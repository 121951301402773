// 
// RootView.js
// Cacao
// 
// Created on 8/23/22
// 

import { assert } from "../core";
import View from "./View";

class RootView extends View {
  #container;
  
  constructor(container){
    super();
    
    assert(container, "A `container` is required.");
    assert(container instanceof HTMLElement, "`container` is not an HTMLElement.");
    
    this.#container = container;
    this.attach();
  }
  
  get container(){
    return this.#container;
  }
  
  attach(){
    try {
      this.#container.appendChild(this.node);
    } catch {
      throw new Error(`Failed to connect RootView to container.`); 
    }
  }
  
  detach(){
    const parent = this.node.parent;
    if (parent) {
      parent.removeChild(this.node);
    }
  }
}

export default RootView;
