// 
// MappedImage.js
// portfolio
// 
// Created on 9/12/23
// 

const rename = (filename, extension) => `${filename.substring(0, filename.lastIndexOf("."))}.${extension}`;

class MappedImage {
  src;
  webp;
  dark;
  
  constructor(source, assets, darkAssets) {
    this.src = assets[source.src];
    this.webp = assets[rename(source.src, "webp")];
    
    if (darkAssets) {
      this.dark = new MappedImage(source, darkAssets);
    }
  }
  
}

export default MappedImage;
