// 
// SidebarView.js
// portfolio
// 
// Created on 6/18/23
// 

import { View } from "cacao/ui";
import { Interpolation } from "cacao/graphics";
const { lerp } = Interpolation;

class SidebarView extends View {
  delegate;
  yearNode;
  
  constructor({ header, navigation, featurette, footer } = {}){
    super();
    
    this.node.innerHTML = `
    <div class="sidebar-wrapper">
      <div class="sidebar-content">
        <header>
          <h1>
            <span role="text">${header.title.replace(" ", `<br>`)}</span>
          </h1>
          <h3><span class="year">${header.year}</span></h3>
        </header>
        
        <nav>
          <ul>
            ${navigation.map(({ title, url, inline, rel } = {}) => 
              `<li><a href="${url}" ${inline ? "data-open-inline" : `target="_blank"`} ${rel ? `rel="${rel}"` : ""}>${title}</a></li>`
            ).join("")}
          </ul>
        </nav>
        
        ${ (featurette) ? `
          <nav>
            <a href="${featurette.url}" class="featurette">
              <figure>
                <img src="${featurette.image.src}" width="${featurette.image.size[0]}" height="${featurette.image.size[1]}" alt="${featurette.image.alt}">
              </figure>
              
              <span class="label">
                <span class="title">${featurette.title}</span>
                <span class="subtitle">${featurette.subtitle}</span>
              </span>
            </a>
          </nav>` : "" }
      </div>
      
      <footer>
        <div class="footer-content">${footer}</div>
      </footer>
    </div>
    `;
    
    const handleInlineClickEvent = (event) => {
      event.preventDefault();
      
      const identifier = event.target.getAttribute("href");
      
      const { delegate } = this;
      if (delegate) {
        delegate.sidebarDidSelectLink(this, identifier);
      }
    };
    
    for (const element of this.node.querySelectorAll("a[data-open-inline]")) {
      element.addEventListener("click", handleInlineClickEvent);
    }
    
    this.yearNode = this.node.querySelector(".year");
  }
  
  configureDisappearPercent(disappearPercent = 0.0){
    const maximumScale = 1, minimumScale = 0.95;
    const scale = lerp(maximumScale, minimumScale, disappearPercent);
    const isHidden = disappearPercent >= 0.99;
    
    const { style, classList } = this.node;
    style.transform = new DOMMatrix().scale(scale);
    style.opacity = 1 - disappearPercent;
    
    classList.toggle("is-hidden", isHidden);
  }
  
  configureCurrentYear(year){
    this.yearNode.textContent = year;
  }
}

export default SidebarView;
