// 
// ScrollEngine.js
// portfolio
// 
// Created on 5/29/23
// 

import { PropertyAnimator, SpringTimingParameters } from "cacao/ui";
import { Point, Size, EdgeInsets, Rect, RubberBanding } from "cacao/graphics";

import ScrollEngineDecelerationRate from "./ScrollEngineDecelerationRate";

class LockedDirection {
  static none = 0;
  static horizontal = 1;
  static vertical = 2;
}

class ScrollEngine {
  _contentOffset = Point.zero;
  _contentSize = Size.zero;
  _contentInset = EdgeInsets.zero;
  _boundsSize = Size.zero;
  _lockedDirection = LockedDirection.none;
  
  decelerationRate = ScrollEngineDecelerationRate.normal;
  directionalLockEnabled = true;
  
  delegate;
  _anchorLimits = [];
  
  initialPosition = Point.zero;
  currentPosition = Point.zero;
  animator;
  
  get contentInset(){
    return this._contentInset.copy();
  }
  
  set contentInset(contentInset){
    this._contentInset = EdgeInsets.from(contentInset);
  }
  
  get boundsSize(){
    return this._boundsSize.copy();
  }
  
  set boundsSize(size){
    this._boundsSize = Size.from(size);
  }
  
  get contentOffset(){
    return this._contentOffset.copy();
  }
  
  set contentOffset(offset){
    this._contentOffset = Point.from(offset);
  }
  
  get contentSize(){
    return this._contentSize.copy();
  }
  
  set contentSize(size){
    this._contentSize = Size.from(size);
  }
  
  setContentOffset(contentOffset, animated){
    if (animated){
      
    } else {
      this.contentOffset = contentOffset;
    }
  }
  
  set anchorLimits(limits){
    if (!limits) {
      limits = [];
    }
    this._anchorLimits = limits.map(limit => Point.from(limit));
  }
  
  get anchorLimits(){
    return this._anchorLimits.map(limit => Point.from(limit));
  }
  
  stop(){
   if (this.animator) {
     this.animator.stopAnimation();
   }
   this.delegate = undefined;
  }
  
  startDragging(){
    this._initialContentOffset = this.contentOffset;
    this.stop();
  }
  
  continueDragging(dx, dy){
    const proposedOffset = { x: this._initialContentOffset.x + dx, y: this._initialContentOffset.y + dy };
    let offset = this.clampedContentOffset(proposedOffset);
    
    if (this.directionalLockEnabled) {
      if (this._lockedDirection == LockedDirection.none) {
        this._lockedDirection = this._determineLockedDirection({ x: dx, y: dy });
      }
      
      offset = this._restrictedContentOffset(offset);
    }
    
    this.contentOffset = offset;
  }
  
  _restrictedContentOffset(offset){
    offset = Point.from(offset);
    switch(this._lockedDirection){
      case LockedDirection.vertical:
      offset.x = this._initialContentOffset.x;
      break;
      case LockedDirection.horizontal:
      offset.y = this._initialContentOffset.y;
      break;
      case LockedDirection.none:
      break;
    }
    return offset;
  }
  
  _determineLockedDirection(translation){
    const horizontalThreshold  = 5;
    const verticalThreshold = 5;
    
    const absX = Math.abs(translation.x);
    const absY = Math.abs(translation.y);
    
    if (absX > horizontalThreshold && absX > absY) {
        return LockedDirection.horizontal;
    } else if (absY > verticalThreshold && absY > absX) {
        return LockedDirection.vertical;
    }
    
    return LockedDirection.none;
  }
  
  endDragging(parameters, delegate){
    this.delegate = delegate;
    
    const {
      decelerationVelocity = { x: 0, y: 0 }
    } = parameters;
    
    if (this.anchorLimits.length > 0) {
      return this.moveOriginToTheNearestAnchor({ decelerationVelocity });
      
    } else {
      const decelerationRate = this.decelerationRate;
      const projection = {
        x: this.contentOffset.x + project(decelerationVelocity.x / 1000, decelerationRate),
        y: this.contentOffset.y + project(decelerationVelocity.y / 1000, decelerationRate)
      };
      
      let clampedTarget = this._confinedContentOffset(projection);
      
      if (this.directionalLockEnabled) {
        clampedTarget = this._restrictedContentOffset(clampedTarget);
      }
      
      return this.animateOriginTo(clampedTarget, { decelerationVelocity });
    }
  }
  
  // -
  
  _confinedContentOffset(contentOffset){
    contentOffset = Point.make(contentOffset.x * -1.0, contentOffset.y * -1.0);
    
    const { contentSize, boundsSize, contentInset } = this;
    
    const bounds = new Rect(this._contentOffset, boundsSize);
    const scrollerBounds = bounds.insetByEdgeInsets(contentInset);
    
    if (contentSize.width - contentOffset.x < scrollerBounds.width) {
      contentOffset.x = contentSize.width - scrollerBounds.width;
    }
    
    if (contentSize.height - contentOffset.y < scrollerBounds.size.height) {
      contentOffset.y = contentSize.height - scrollerBounds.size.height;
    }
    
    contentOffset.x = Math.max(contentOffset.x, 0);
    contentOffset.y = Math.max(contentOffset.y, 0);
    
    if (contentSize.width <= scrollerBounds.size.width) {
        contentOffset.x = 0;
    }
    
    if (contentSize.height <= scrollerBounds.size.height) {
        contentOffset.y = 0;
    }
    
    return Point.make(-contentOffset.x, -contentOffset.y);
  }
  
  // -
  
  projectionForDragging(parameters){
    const { decelerationVelocity } = parameters;
    
    const decelerationRate = this.decelerationRate;
    const projection = {
      x: this.contentOffset.x + project(decelerationVelocity.x / 1000, decelerationRate),
      y: this.contentOffset.y + project(decelerationVelocity.y / 1000, decelerationRate)
    };
    
    return projection;
  }
  
  nearestAnchorForDragging(parameters){
    const projection = this.projectionForDragging(parameters);
    
    let origin = projection;
    if (this.directionalLockEnabled) {
      origin = this._restrictedContentOffset(origin);
    }
    
    return this.selectNearestAnchorToOrigin(origin);
  }
  
  moveOriginToTheNearestAnchor(parameters){
    const projectionAnchor = this.nearestAnchorForDragging(parameters);
    
    return this.animateOriginTo(projectionAnchor, parameters);
  }
  
  selectNearestAnchorToOrigin(origin){
    let minimumDistance = Infinity;
    let closestPosition;
    
    const calculateDistance = (a, b) => {
      const differential = { x: b.x - a.x, y: b.y - a.y };
      const { x, y } = differential;
      
      const hypotenuse = Math.sqrt(x * x + y * y);
      return hypotenuse;
    };
    
    for (const anchor of this._anchorLimits) {
      const distance = calculateDistance(anchor, origin);
      if (distance < minimumDistance) {
        closestPosition = anchor;
        minimumDistance = distance;
      }
    }
    
    return closestPosition;
  }
  
  animateOriginTo(origin, parameters){
    const { decelerationVelocity } = parameters;
    
    const initialPosition = this.contentOffset;
    const finalPosition = Point.from(origin);
    
    if (initialPosition.x == finalPosition.x && initialPosition.y == finalPosition.y) {
      return false;
    }
    
    const animationVelocity = calculateInitialAnimationVelocity(decelerationVelocity, initialPosition, finalPosition);
    
    if (this.directionalLockEnabled) {
      switch (this._lockedDirection){
        case LockedDirection.horizontal:
        animationVelocity.dy = 0;
        break;
        case LockedDirection.vertical:
        animationVelocity.dx = 0;
        break;
      }
    }
    
    const duration = 800;
    const options = { timingParameters: new SpringTimingParameters({ damping: 18, initialVelocity: animationVelocity }) };
    
    const position = initialPosition.copy();
    
    const animator = new PropertyAnimator(options);
    animator.didAnimate = () => {
      this.setContentOffset(position);
      
      const { delegate } = this;
      
      if (delegate){
        delegate.didAnimate();
      }
    };
    
    if (this.delegate) {
      this.delegate.willAnimate(finalPosition);
    }
    
    animator.animate(position, finalPosition, duration, (completed) => {
      if (completed) {
        this._lockedDirection = LockedDirection.none;
      }
      
      const { delegate } = this;
      if (delegate) {
        delegate.didFinish(completed);
      }
    });
    
    this.animator = animator;
    
    return true;
  }
  
  clampedContentOffset(contentOffset){
    const clampedTarget = (target, lowerBound, upperBound, boundsDimension) => {
      if (target > lowerBound) {
        const diff = target - lowerBound;
        const dim = Math.abs(boundsDimension - lowerBound);
        
        return lowerBound + RubberBanding.clamp(diff, { dim });
      } else if (target < upperBound) {
        const diff = upperBound - target;
        const dim = Math.abs(boundsDimension - upperBound);
        
        return upperBound - RubberBanding.clamp(diff, { dim });
      } else {
        return target;
      }
    };
    
    const { contentSize, boundsSize } = this;
    const maxX = -contentSize.width + boundsSize.width;
    const maxY = -contentSize.height + boundsSize.height;
    
    const offset = Point.make(
      clampedTarget(contentOffset.x, 0, maxX, boundsSize.width),
      clampedTarget(contentOffset.y, 0, maxY, boundsSize.height)
    );
    
    return offset;
  }
  
}

function project(initialVelocity, decelerationRate) {
  if (decelerationRate >= 1) {
    throw "decelerationRate must be greater than one.";
  }
  
  return initialVelocity * decelerationRate / (1 - decelerationRate);
}

// https://developer.apple.com/documentation/uikit/uispringtimingparameters/1649909-initialvelocity
function calculateInitialAnimationVelocity(gestureVelocity, currentPosition, finalPosition){
  let animationVelocity = { dx: 0, dy: 0 };
  let xDistance = finalPosition.x - currentPosition.x;
  let yDistance = finalPosition.y - currentPosition.y;
  if (xDistance != 0) {
    animationVelocity.dx = gestureVelocity.x / xDistance;
  }
  if (yDistance != 0) {
    animationVelocity.dy = gestureVelocity.y / yDistance;
  }
  return animationVelocity;
}

export default ScrollEngine;
