const _temp0 = require("../res/pages/work/dark-color-scheme/airmail.png");
const _temp1 = require("../res/pages/work/dark-color-scheme/airmail.webp");
const _temp2 = require("../res/pages/work/dark-color-scheme/albums.png");
const _temp3 = require("../res/pages/work/dark-color-scheme/albums.webp");
const _temp4 = require("../res/pages/work/dark-color-scheme/controls.png");
const _temp5 = require("../res/pages/work/dark-color-scheme/controls.webp");
const _temp6 = require("../res/pages/work/dark-color-scheme/cornershop-design-principles.png");
const _temp7 = require("../res/pages/work/dark-color-scheme/cornershop-design-principles.webp");
const _temp8 = require("../res/pages/work/dark-color-scheme/cornershop-ipad.png");
const _temp9 = require("../res/pages/work/dark-color-scheme/cornershop-ipad.webp");
const _temp10 = require("../res/pages/work/dark-color-scheme/cornershop-iphone.png");
const _temp11 = require("../res/pages/work/dark-color-scheme/cornershop-iphone.webp");
const _temp12 = require("../res/pages/work/dark-color-scheme/cornershop-landing.png");
const _temp13 = require("../res/pages/work/dark-color-scheme/cornershop-landing.webp");
const _temp14 = require("../res/pages/work/dark-color-scheme/cornershop-new-web.png");
const _temp15 = require("../res/pages/work/dark-color-scheme/cornershop-new-web.webp");
const _temp16 = require("../res/pages/work/dark-color-scheme/cornershop-shopper-android.png");
const _temp17 = require("../res/pages/work/dark-color-scheme/cornershop-shopper-android.webp");
const _temp18 = require("../res/pages/work/dark-color-scheme/cornershop-shopper-ios.png");
const _temp19 = require("../res/pages/work/dark-color-scheme/cornershop-shopper-ios.webp");
const _temp20 = require("../res/pages/work/dark-color-scheme/cornershop-shopper-welcome.png");
const _temp21 = require("../res/pages/work/dark-color-scheme/cornershop-shopper-welcome.webp");
const _temp22 = require("../res/pages/work/dark-color-scheme/delivery.png");
const _temp23 = require("../res/pages/work/dark-color-scheme/delivery.webp");
const _temp24 = require("../res/pages/work/dark-color-scheme/figurative.png");
const _temp25 = require("../res/pages/work/dark-color-scheme/figurative.webp");
const _temp26 = require("../res/pages/work/dark-color-scheme/mmnumberkeyboard.png");
const _temp27 = require("../res/pages/work/dark-color-scheme/mmnumberkeyboard.webp");
const _temp28 = require("../res/pages/work/dark-color-scheme/nsfw.png");
const _temp29 = require("../res/pages/work/dark-color-scheme/nsfw.webp");
const _temp30 = require("../res/pages/work/dark-color-scheme/pase-pro.png");
const _temp31 = require("../res/pages/work/dark-color-scheme/pase-pro.webp");
const _temp32 = require("../res/pages/work/dark-color-scheme/posterama.png");
const _temp33 = require("../res/pages/work/dark-color-scheme/posterama.webp");
const _temp34 = require("../res/pages/work/dark-color-scheme/review.png");
const _temp35 = require("../res/pages/work/dark-color-scheme/review.webp");
const _temp36 = require("../res/pages/work/dark-color-scheme/seahorse-ios6.png");
const _temp37 = require("../res/pages/work/dark-color-scheme/seahorse-ios6.webp");
const _temp38 = require("../res/pages/work/dark-color-scheme/seahorse-ios7.png");
const _temp39 = require("../res/pages/work/dark-color-scheme/seahorse-ios7.webp");
const _temp40 = require("../res/pages/work/dark-color-scheme/trojan-avocado.png");
const _temp41 = require("../res/pages/work/dark-color-scheme/trojan-avocado.webp");
const _temp42 = require("../res/pages/work/dark-color-scheme/uber-grocery.png");
const _temp43 = require("../res/pages/work/dark-color-scheme/uber-grocery.webp");
const _temp44 = require("../res/pages/work/dark-color-scheme/written.png");
const _temp45 = require("../res/pages/work/dark-color-scheme/written.webp");

module.exports = {
  "work":   {
    "airmail.png": _temp0,
    "airmail.webp": _temp1,
    "albums.png": _temp2,
    "albums.webp": _temp3,
    "controls.png": _temp4,
    "controls.webp": _temp5,
    "cornershop-design-principles.png": _temp6,
    "cornershop-design-principles.webp": _temp7,
    "cornershop-ipad.png": _temp8,
    "cornershop-ipad.webp": _temp9,
    "cornershop-iphone.png": _temp10,
    "cornershop-iphone.webp": _temp11,
    "cornershop-landing.png": _temp12,
    "cornershop-landing.webp": _temp13,
    "cornershop-new-web.png": _temp14,
    "cornershop-new-web.webp": _temp15,
    "cornershop-shopper-android.png": _temp16,
    "cornershop-shopper-android.webp": _temp17,
    "cornershop-shopper-ios.png": _temp18,
    "cornershop-shopper-ios.webp": _temp19,
    "cornershop-shopper-welcome.png": _temp20,
    "cornershop-shopper-welcome.webp": _temp21,
    "delivery.png": _temp22,
    "delivery.webp": _temp23,
    "figurative.png": _temp24,
    "figurative.webp": _temp25,
    "mmnumberkeyboard.png": _temp26,
    "mmnumberkeyboard.webp": _temp27,
    "nsfw.png": _temp28,
    "nsfw.webp": _temp29,
    "pase-pro.png": _temp30,
    "pase-pro.webp": _temp31,
    "posterama.png": _temp32,
    "posterama.webp": _temp33,
    "review.png": _temp34,
    "review.webp": _temp35,
    "seahorse-ios6.png": _temp36,
    "seahorse-ios6.webp": _temp37,
    "seahorse-ios7.png": _temp38,
    "seahorse-ios7.webp": _temp39,
    "trojan-avocado.png": _temp40,
    "trojan-avocado.webp": _temp41,
    "uber-grocery.png": _temp42,
    "uber-grocery.webp": _temp43,
    "written.png": _temp44,
    "written.webp": _temp45
  }
}