// 
// Interpolation.js
// Cacao
// 
// Created on 6/8/23
// 

function lerp(x, y, a){
  return x * (1 - a) + y * a;
}

function clamp(a, min = 0, max = 1){
  return Math.min(max, Math.max(min, a));
}

function invlerp(a, b, value){
  return (value - a) / (b - a);
}

function range(x1, y1, x2, y2, a){
  return lerp(x2, y2, invlerp(x1, y1, a));
}

export default {
  lerp,
  clamp,
  range,
  invlerp
}