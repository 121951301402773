// 
// GestureRecognizer.js
// Cacao
// 
// Created on 6/2/23
// 

import Touch from "./Touch.js"
import TouchType from "./TouchType"
import State from "./GestureRecognizerState.js"

class GestureRecognizer {
  enabled;
  didChange;
  shouldBegin;
  
  constructor(){
    this._state = State.possible;
    this._touches = [];
    this.enabled = true;
  }
  
  reset(){
    this._state = State.possible;
    this._event = undefined;
  }
  
  set state(state){
    if (this.shouldBegin && this._state == State.possible && (state == State.recognized || state == State.began)) {
      if (!this.shouldBegin(this)){
        state = State.failed;
      }
    }
    
    const allowedStateTransitions = [
      // Discrete gestures:
      [ State.possible, State.recognized, true ],
      [ State.possible, State.failed, false ],
      
      // Continuous gestures:
      [ State.possible, State.began, true],
      [ State.began, State.changed, true ],
      [ State.began, State.cancelled, true ],
      [ State.began, State.ended, true ],
      [ State.changed, State.changed, true ],
      [ State.changed, State.cancelled, true ],
      [ State.changed, State.ended, true ]
    ];
    
    let transition;
    
    for (const [ fromState, toState, notify ] of allowedStateTransitions) {
      if (fromState == this._state && toState == state) {
        transition = { fromState, toState, notify };
        break;
      }
    }
    
    if (!transition) {
      throw new Error(`GestureRecognizer: Invalid state transition from ${this._state} to ${state}.`);
    }
    
    if (transition) {
      this._state = state;
      
      if (transition.notify && this.didChange) {
        this.didChange(this);
      }
    }
  }
  
  get state(){
    return this._state;
  }
  
  get event(){
    return this._event;
  }
  
  began(touches, event){
    //
  }
  
  moved(touches, event){
    //
  }
  
  ended(touches, event){
    //
  }
  
  cancelled(touches, event){
    //
  }
  
  handleEvent(event){
    // For now, only single-touch (and mouse) events are supported:
    if (!event.isPrimary) {
      return;
    }
    
    this._event = event;
    
    const touch = new Touch();
    touch.identifier = event.pointerId;
    touch.x = event.clientX;
    touch.y = event.clientY;
    touch.tapCount = 1; // TODO
    
    if (event.pointerType) {
      switch (event.pointerType) {
        case "mouse":
          touch.type = TouchType.indirect;
          break;
        case "pen":
          touch.type = TouchType.pen;
          break;
        case "touch":
          touch.type = TouchType.direct;
          break;
        default:
          touch.type = TouchType.indirect;
          break;
      }
    } else {
      touch.type = TouchType.indirect;
    }
    
    if (event.type == "pointerdown") {
      const continueTrackingOnWindow = true; // Expose this behavior?
      const target = continueTrackingOnWindow ? window : event.currentTarget;
      
      target.addEventListener("pointermove", this);
      target.addEventListener("pointerup", this);
      target.addEventListener("pointercancel", this);
      
      this._currentTarget = target;
    }
    
    const touches = [ touch ];
    this._touches = touches;
    
    // Deliver touches if the gesture has not yet failed.
    if (this.state != State.failed) {
      switch (event.type) {
      case "pointerdown":
        this.began(touches, event);
        break;
      case "pointermove":
        this.moved(touches, event);
        break;
      case "pointerup":
        this.ended(touches, event);
        break;
      case "pointercancel":
        this.cancelled(touches, event);
        break;
      }
    }
    
    switch(event.type){
      case "pointerup":
      case "pointercancel":
      const target = this._currentTarget;
      target.removeEventListener("pointermove", this);
      target.removeEventListener("pointerup", this);
      target.removeEventListener("pointercancel", this);
      
      this.reset();
      break;
    }
  }
  
  attach(target){
    target.addEventListener("pointerdown", this);
  }
  
  locationInView(view){
    // TODO: Convert to view coordinates.
    const touch = this._touches[0];
    if (touch) {
      const { x, y } = touch;
      return { x, y };
    }
    return undefined;
  }
  
  touchesInView(view){
    // TODO: Return touches for the view.
    return this._touches.slice(0);
  }
  
  canPreventGestureRecognizer(preventingGestureRecognizer){
    return true;
  }
  
  canBePreventedByGestureRecognizer(preventedGestureRecognizer){
    return true;
  }
  
}

export default GestureRecognizer
