// 
// TimelineView.js
// portfolio
// 
// Created on 5/7/23
// 

import { View, PanGestureRecognizer, TapGestureRecognizer, GestureRecognizerState, TouchType } from "cacao/ui"
import { EdgeInsets, Size, Point, Interpolation } from "cacao/graphics";

import ScrollEngine from "../scrolling/ScrollEngine";
import TimelineViewCell from "./TimelineViewCell";
import TimelineInteractionCompletion from "./TimelineInteractionCompletion";
import CaptionView from "./CaptionView";
import SnapRulerView from "./SnapRulerView";

import {
  TimelineLayoutOptions,
  TimelineLayoutMode,
  CaptionPositionMode,
  TimelineLayout } from "./Layout";
  
const { invlerp, clamp } = Interpolation;

class TimelineView extends View {
  delegate;
  
  cells;
  rulerView;
  
  animator;
  layout;
  
  constructor(data){
    super();
    this._containerSize = Size.zero;
    this.data = data;
    
    this.node.className = "timeline-container";
    this.node.addEventListener("scroll", this);
    
    const wrapperView = new View();
    this.wrapperView = wrapperView;
    this.addSubview(wrapperView);
    
    const subviews = data.map(item => new TimelineViewCell(this));
    subviews.forEach(subview => wrapperView.addSubview(subview));
    
    this.cells = subviews;
    
    const rulerView = new SnapRulerView();
    wrapperView.addSubview(rulerView);
    
    this.rulerView = rulerView;
    
    this.addInteractions();
  }
  
  // - Interaction setup.
  
  addInteractions(){
    const handlePanGesture = () => {
      const { state, translation, velocity } = gestureRecognizer;
        
      if (state == GestureRecognizerState.began) {
        if (this.animator) {
          this.animator.stop();
        }
        
        const isTouchTypeDirect = gestureRecognizer.touchesInView(this)[0].type == TouchType.direct;
        const minimumTranslationForMovement = isTouchTypeDirect ? 5 : 0;
        
        const location = gestureRecognizer.locationInView(this);
        const adjustedLocation = Point.make(location.x, location.y + this.node.scrollTop);
        
        const transition = this.layout.beginInteractiveTransition(adjustedLocation, { minimumTranslationForMovement });
        transition.interactionHasMovement = () => {
          this.isScrollDisabled = true;
          this.layoutSubviews();
        };
        
        this.transition = transition;
        this.layoutSubviews();
        
      } else if (state == GestureRecognizerState.changed) {
        this.transition.updateInteractiveMovement({ translation });
        this.layoutSubviews();
        
      } else if (state == GestureRecognizerState.ended) {
        this.isScrollDisabled = false;
        
        // Don't decelerate if already completing to prioritize tap gesture:
        if (!this.isPendingInteractionCompletion) {
          const decelerate = TimelineInteractionCompletion.decelerate({ decelerationVelocity: velocity, animated: true });
          this.completeInteraction(decelerate);
        }
        
      } else if (state == GestureRecognizerState.cancelled) {
        this.isScrollDisabled = false;
        this.completeInteraction(TimelineInteractionCompletion.cancel({ animated: true }));
      }
    };
    
    const handlePanGestureShouldBegin = () => {
      return this.transition == undefined;
    };
    
    const gestureRecognizer = new PanGestureRecognizer();
    gestureRecognizer.didChange = handlePanGesture;
    gestureRecognizer.shouldBegin = handlePanGestureShouldBegin;
    
    gestureRecognizer.attach(this.node);
    
    const handleTapGesture = () => {
      const { state } = tapGestureRecognizer;
      
      if (state == GestureRecognizerState.recognized) {
        if (!this.transition) {
          return;
        }
        
        let completion;
        
        if (this.layout.currentMode == TimelineLayoutMode.compressed) {
          const location = tapGestureRecognizer.locationInView(this.node);
          const adjustedLocation = Point.make(location.x, location.y + this.node.scrollTop);
          
          const focusedIndex = this.transition.focusedIndex;
          const didTapInsideItemBounds = this.layout.layoutAttributes[focusedIndex].frame.containsPoint(adjustedLocation);
          
          if (didTapInsideItemBounds) {
            completion = TimelineInteractionCompletion.focus({ animated: true });
          }
        }
        
        if (!completion) {
          completion = TimelineInteractionCompletion.cancel({ animated: true });
        }
        
        this.completeInteraction(completion);
      }
    };
    
    const tapGestureRecognizer = new TapGestureRecognizer();
    tapGestureRecognizer.didChange = handleTapGesture;
    
    tapGestureRecognizer.attach(this.node);
  }
  
  // - Layout.
  
  invalidateLayout(){
    const { data, containerSize } = this;
    
    const layoutDelegate = {
      numberOfItems: data.length,
      
      imageSizeForItem(itemIndex){
        const [ width, height ] = data[itemIndex].image.size;
        return Size.make(width, height);
      },
      
      captionHeightForItem(itemIndex, fittingWidth){
        return CaptionView.calculateMinimumHeightForItem(data[itemIndex], fittingWidth);
      }
    };
    
    const minimumEdgeSpacing = 32.0;
    
    const options = new TimelineLayoutOptions();
    options.maximumRegularImageSize = Size.make(700, 700);
    options.minimumCaptionWidth = 280;
    options.captionSpacing = 32;
    options.captionPositioningMode = CaptionPositionMode.anchorToBottom;
    options.contentInset = EdgeInsets.make(minimumEdgeSpacing, minimumEdgeSpacing, minimumEdgeSpacing, minimumEdgeSpacing);
    options.maximumCompressedImageSize = Size.make(100, 100);
    options.minimumInterSpacing = 32;
    options.boundsSize = containerSize;
    
    const minimumWidthForPositioningCaptionRight = 500.0;
    if (containerSize.width > minimumWidthForPositioningCaptionRight) {
      options.captionPositioningMode = CaptionPositionMode.right;
    }
    
    const maximumReadableContentWidth = 900.0;
    const readableContentInset = minimumEdgeSpacing + Math.ceil(Math.max(containerSize.width - maximumReadableContentWidth, 0) / 2.0);
    options.contentInset.left = readableContentInset;
    options.contentInset.right = readableContentInset;
    
    if (!this.layout) {
      this.layout = new TimelineLayout(options, layoutDelegate);
    } else {
      this.layout.options = options;
    }
    
    this.rulerView.stops = this.data.map((_, index) => containerSize.height * index);
  }
  
  layoutSubviews(){
    if (this.containerSize.equals(Size.zero)){
      return;
    }
    
    if (this._containerSizeInvalidated) {
      this.invalidateLayout();
      this._containerSizeInvalidated = false;
    }
    
    const { data, layout, containerSize } = this;
    
    // Layout:
    layout.layout();
    
    // Determine layout state:
    const isTransitionActive = (!!this.transition);
    const isCurrentModeRegular = (layout.currentMode == TimelineLayoutMode.regular);
    const isCaptionPositioningAnchored = (layout.options.captionPositioningMode == CaptionPositionMode.anchorToBottom);
    const isSnapActive = isCurrentModeRegular && !isTransitionActive;
    const isScrollDisabled = (this.isScrollDisabled);
    
    // Determine options for cell:
    const optionsForCell = {
      selectable: !isTransitionActive && !isCurrentModeRegular,
    };
    
    // Apply layout:
    for (const attributes of layout.layoutAttributes) {
      const cell = this.cells[attributes.itemIndex];
      const dataForItem = data[attributes.itemIndex];
      
      cell.configure({ attributes, data: dataForItem, options: optionsForCell });
    }
    
    // Update container and views:
    const { contentSize } = layout;
    
    const { style: nodeStyle } = this.node;
    nodeStyle.position = "relative";
    nodeStyle.width = `${containerSize.width}px`;
    nodeStyle.height = `${containerSize.height}px`;
    nodeStyle.overflowX = "hidden";
    nodeStyle.overflowY = isScrollDisabled ? "hidden" : "scroll";
    nodeStyle.scrollSnapType = isSnapActive ? "y mandatory" : "none";
    
    const { style: wrapperStyle } = this.wrapperView.node;
    wrapperStyle.width = `${contentSize.width}px`;
    wrapperStyle.height = `${contentSize.height}px`;
    wrapperStyle.overflow = "hidden";
    wrapperStyle.touchAction = "pan-y zoom";
    
    this.rulerView.enabled = isSnapActive;
    
    const { classList } = this.node; 
    classList.toggle("compressed", !isCurrentModeRegular);
    classList.toggle("caption-anchored-bottom", isCaptionPositioningAnchored);
    classList.toggle("caption-right", !isCaptionPositioningAnchored);
    
    this._triggerEventForFocusLayout();
  }
  
  set containerSize(size){
    if (!this._containerSize.equals(size)) {
      this._containerSize = Size.from(size);
      this._containerSizeInvalidated = true;
    }
  }
  
  get containerSize(){
    return this._containerSize.copy();
  }
  
  // - Interaction completion.
  
  completeInteraction(parameters){
    this._finishingParameters = parameters;
    
    // Schedule if needed:
    if (!this._finishing) {
      this._finishing = true;
      
      const finish = () => {
        this._finishing = false;
        this._completeInteraction(this._finishingParameters);
      };
      
      window.requestAnimationFrame(finish);
    }
  }
  
  get isPendingInteractionCompletion(){
    return this._finishing;
  }
  
  // - Performs a layout transition:
  
  _beginTransitionTo(targetLayoutMode, targetScrollTop){
    const { layout, transition } = this;
    
    // Calculate the target scroll top and re-anchor the screen to it:
    const scrollTop = this.node.scrollTop;
    if (scrollTop != targetScrollTop) {
      // Disable scroll for a moment and set it:
      const { style: nodeStyle } = this.node;
      const { overflowY } = nodeStyle;
      nodeStyle.overflowY =  "hidden";
      this.node.scrollTop = targetScrollTop;
      nodeStyle.overflowY = overflowY;
      
      // Prevents bug in Safari were the actual UIScrollView keeps decelerating to an incorrect target:
      window.requestAnimationFrame(() => {
        this.node.scrollTop = targetScrollTop;
      });
    }
    
    // Let's also calculate a translation vector for what we just did:
    const scrollTopTranslation = Point.make(0, targetScrollTop - scrollTop);
    
    // Re-center the currently focused item on the visible area of the screen:
    const focusedItemAttributes = layout.layoutAttributes[transition.focusedIndex];
    const focusedItemOrigin = Point.from(focusedItemAttributes.frame.origin);
    focusedItemOrigin.y += scrollTopTranslation.y;
    
    // Configure the transition to the correct target mode:
    transition.targetMode = targetLayoutMode;
    
    // Drive transition with progress zero to prevent flick:
    this._driveTransition({ progressVector: Point.zero, focusedItemOrigin, scrollTopTranslation });
    
    // Notify:
    this._triggerEventForTransition(true, transition);
    
    return { scrollTopTranslation, focusedItemOrigin };
  }
  
  _driveTransition(state){
    const { transition } = this;
    const { progressVector, focusedItemOrigin, scrollTopTranslation } = state;
    
    transition.setTransitionProgress(progressVector, {
      // Animate the focused cell independently:
      focusedItemOrigin,
      
      // Preserve the translation:
      translation: scrollTopTranslation
    });
    
    this.layoutSubviews();
  }
  
  _endTransition(){
    const { transition } = this;
    
    transition.complete(true);
    
    this.transition = undefined;
    this.animator = undefined;
    
    this.layoutSubviews();
    this._triggerEventForTransition(false, transition);
  }
  
  _completeInteraction(completion){
    const { layout, transition } = this;
    
    if (!transition) {
      throw new Error("Internal consistency error: No transition to finish.");
    }
    
    if (this.isCompletingInteraction){
      return;
    }
    
    this.isCompletingInteraction = true;
    
    const calculateScrollTopForCenteringItem = (focusedIndex, mode) => {
      const { containerSize } = this;
      
      if (mode == TimelineLayoutMode.regular) {
        return (containerSize.height * focusedIndex);
      } else {
        const { frame } = layout.finalLayoutAttributesForItem(focusedIndex, mode);
        const { containerSize } = this;
        
        let scrollTop = Math.floor(frame.minY - (containerSize.height - frame.height));  
        scrollTop = Math.max(scrollTop, 0);
        scrollTop = Math.min(scrollTop, this.layout.contentSizeForMode(mode).height - containerSize.height);
        
        return scrollTop;
      }
    };
    
    const solveCompletion = (completion) => {
      let targetLayoutMode, targetScrollTop;
      
      const { focus, unfocus, cancel, decelerate } = TimelineInteractionCompletion.type;
      switch (completion.type){
        case focus:
        case unfocus:
          targetLayoutMode = (completion.type == focus) ? TimelineLayoutMode.regular : TimelineLayoutMode.compressed;
          targetScrollTop = calculateScrollTopForCenteringItem(transition.focusedIndex, targetLayoutMode);
          break;
        case cancel:
          targetLayoutMode = layout.currentMode;
          targetScrollTop = this.node.scrollTop;
          break;
        case decelerate:
          const { decelerationVelocity } = completion.parameters;
          const fi = transition.focusedIndex;
          
          const regular = layout.finalLayoutAttributesForItem(fi, TimelineLayoutMode.regular).frame.origin.copy();
          const compressed = layout.finalLayoutAttributesForItem(fi, TimelineLayoutMode.compressed).frame.origin.copy();
          regular.y = 0.0, compressed.y = 0.0; // Hack: Make it so they both match the engine's content offset.
          
          const engine = new ScrollEngine();
          engine.directionalLockEnabled = false;
          engine.contentOffset = layout.layoutAttributes[fi].frame.origin;
          engine.anchorLimits = [ regular, compressed ];
          
          const anchor = engine.nearestAnchorForDragging({ decelerationVelocity });
          
          targetLayoutMode = anchor.equals(regular) ? TimelineLayoutMode.regular : TimelineLayoutMode.compressed;
          targetScrollTop = calculateScrollTopForCenteringItem(transition.focusedIndex, targetLayoutMode);
          break;
      }
      
      return { targetLayoutMode, targetScrollTop, animated: completion.parameters.animated };
    };
    
    // Calculate targets:
    const { targetLayoutMode, targetScrollTop, animated } = solveCompletion(completion);
    const focusedItemOriginTarget = layout.finalLayoutAttributesForItem(transition.focusedIndex, targetLayoutMode).frame.origin;
    
    // Begin transition:
    const { scrollTopTranslation, focusedItemOrigin } = this._beginTransitionTo(targetLayoutMode, targetScrollTop);
    
    // Define a callback that ends the transition to call later:
    const completeTransition = () => {
      this._endTransition();
      this.isCompletingInteraction = false;
    };
    
    if (animated) {
      const engine = new ScrollEngine();
      engine.contentSize = layout.contentSizeForMode(targetLayoutMode);
      engine.contentOffset = focusedItemOrigin;
      engine.anchorLimits = [ focusedItemOriginTarget ];
      
      const { decelerationVelocity } = completion.parameters;
      const targetContentOffset = Point.zero;
      
      const animates = engine.endDragging({ decelerationVelocity }, {
        
        willAnimate: (target) => {
          // TODO: Target should override content offset sometimes?
          targetContentOffset.x = target.x;
          targetContentOffset.y = target.y;
        },
        
        didAnimate: () => {
          const offset = engine.contentOffset;
          const from = focusedItemOrigin, to = targetContentOffset;
           
          // Calculate progress vector:
          const progress = Point.make(
            (from.x == to.x) ? NaN : invlerp(from.x, to.x, offset.x),
            (from.y == to.y) ? NaN : invlerp(from.y, to.y, offset.y)
          );
          
          if (isNaN(progress.x)) {
            progress.x = isNaN(progress.y) ? 0.0 : progress.y;
          }
          
          if (isNaN(progress.y)) {
            progress.y = isNaN(progress.x) ? 0.0 : progress.x;
          }
          
          // Drive:
          const state = {
            progressVector: progress,
            scrollTopTranslation,
            focusedItemOrigin: offset
          };
          
          this._driveTransition(state);
        },
        
        didFinish: (completed) => {
          completeTransition();
        }
        
      });
      
      if (!animates) {
        window.requestAnimationFrame(() => completeTransition());
      }
      
    } else {
      completeTransition();
    }
    
    this.layoutSubviews();
  }
  
  // - Delegate
  
  _triggerEventForTransition(beginOrEnd, transition){
    const state = {
      isFocused: (transition.targetMode == TimelineLayoutMode.regular),
      item: transition.focusedIndex
    };
    
    if (beginOrEnd) {
      this.delegate.timelineViewWillTransition(this, state);
    } else {
      this.delegate.timelineViewDidTransition(this, state);
    }
  }
  
  _triggerEventForFocusLayout(){
    const { transition, layout } = this;
    
    let percentComplete;
    if (transition) {
      const from = layout.finalLayoutAttributesForItem(transition.focusedIndex, TimelineLayoutMode.compressed).frame.minX;
      const to = this.containerSize.width / 2.0;
      const current = layout.layoutAttributes[transition.focusedIndex].frame.minX;
      
      percentComplete = invlerp(from, to, current);
      percentComplete = clamp(percentComplete);
    } else {
      percentComplete = (layout.currentMode == TimelineLayoutMode.compressed) ? 0.0 : 1.0;
    }
    
    if (this._focusInteractionPercentComplete != percentComplete) {
      this._focusInteractionPercentComplete = percentComplete;
      
      this.delegate.timelineViewFocusInteractionDidChange(this, { percentComplete });
    }
  }
  
  // - Cell delegate.
  
  didSelectTimelineCellForItem(_, item){
    this.focusView(item, { animated: true });
  }
  
  // - Actions
  
  focusView(item, { animated = false }){
    if (this.transition) {
      return; // Ignore.
    }
    
    this.transition = this.layout.beginInteractiveTransitionCenteredOnItem(item);
    this.layoutSubviews();
    
    const completion = TimelineInteractionCompletion.focus({ animated: animated });
    this.completeInteraction(completion);
  }
  
  popFocusedView({ animated = false } = {}){
    const { layout, transition } = this;
    
    if (transition || layout.currentMode != TimelineLayoutMode.regular) {
      return;
    }
    
    const { height } = this.containerSize;
    let item = Math.round((this.node.scrollTop + (height / 2.0)) / height) - 1;
    item = Math.max(Math.min(item, this.cells.length - 1), 0);
    
    this.transition = this.layout.beginInteractiveTransitionCenteredOnItem(item);
    this.layoutSubviews();
    
    const completion = TimelineInteractionCompletion.unfocus({ animated });
    this.completeInteraction(completion);
  }
  
  get isFocused(){
    return this.layout.currentMode == TimelineLayoutMode.regular;
  }
  
  // - Scroll.
  
  handleScrollDidChange(scrollTop){
    let itemIndex = 0;
    
    if (this.isCompletingInteraction) {
      return;
    }
    
    for (const layout of this.layout.layoutAttributes) {
      if (scrollTop < layout.frame.midY) {
        itemIndex = layout.itemIndex;
        break;
      }
    }
    
    if (this._visibleIndex != itemIndex) {
      this._visibleIndex = itemIndex;
      
      this.delegate.timelineViewDidScroll(this, { itemIndex });
    }
  }
  
  // – Event handling.
  
  handleEvent(event){
    switch (event.type) {
      case "scroll":
        if (!this._trackingScrollEvent){
          this._trackingScrollEvent = true;
          
          const { scrollTop } = this.node; 
          
          window.requestAnimationFrame(() => {
            this.handleScrollDidChange(scrollTop);
            this._trackingScrollEvent = false;
          });
        }
        break;
    }
  }
  
}

export default TimelineView;
