// 
// CaptionView.js
// portfolio
// 
// Created on 6/1/23
// 

import { View } from "cacao/ui";

class CaptionView extends View {
  
  configure(item){
    const { title, role, company, year, team, action } = item;
    
    this.node.innerHTML = `
    <div class="caption-wrapper">
      <header>
        <hgroup>
          <h1>${title}</h2>
          <h2>${role}</h2>
          ${ (company) ?
            `<h2>${company}</h2>`: "" 
          }
        </hgroup>
        
        <time>${year}</time>
        
        ${ (action) ?
          `<a href="${action.url}" class="action" target="_blank">${action.title}</a>` : ""
        }
      </header>
      
      ${ (team && team.length > 0) ? 
      `<aside class="team">
        ${team.map(name => `<p>${name}</p>`).join("") }
      </aside>` : ""
      }
    </div>
    `;
  }
  
  static #measureView = new CaptionView();
  static #measureViewContainer = document.createElement("div");
  
  static calculateMinimumHeightForItem(item, fittingWidth = 0){
    const view = CaptionView.#measureView;
    view.configure(item);
    
    const containerNode = CaptionView.#measureViewContainer;
    containerNode.setAttribute("style", `position: absolute; top: 0; left: 0; width: ${fittingWidth}px; visibility: hidden;`);
    containerNode.appendChild(view.node);
    
    document.body.appendChild(containerNode);
    
    const calculatedHeight = Math.ceil(view.node.offsetHeight);
    containerNode.parentNode.removeChild(containerNode);
    
    
    return calculatedHeight;
  }
  
}

export default CaptionView;
