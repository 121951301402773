// 
// Button.js
// portfolio
// 
// Created on 6/17/23
// 

import { View } from "cacao/ui";

class Button extends View {
  primaryAction;
  _keyEquivalent;
  
  makeNode(document){
    return document.createElement("button");
  }
  
  constructor(){
    super();
    this.node.addEventListener("click", this);
  }
  
  handleEvent(event){
    switch(event.type){
      case "click":
        this._triggerPrimaryAction(event);
        break;
      case "keydown":
        if (this._isKeyEquivalent(event.key)) {
          this._triggerPrimaryAction(event);
        }
        break;
    }
  }
  
  _triggerPrimaryAction(event) {
    const { primaryAction } = this;
    if (primaryAction){
      primaryAction(event);
    }
  }
  
  set keyEquivalent(chars){
    this._keyEquivalent = chars;
    
    if (chars) {
      document.addEventListener("keydown", this);
    } else {
      document.removeEventListener("keydown", this);
    }
  }
  
  get keyEquivalent(){
    return this._keyEquivalent;
  }
  
  _isKeyEquivalent(key){
    const { _keyEquivalent: record } = this;
    if (Array.isArray(record)) {
      return record.indexOf(key) != -1;
    } else {
      return record === key;
    }
  }
}

export default Button;
