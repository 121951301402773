// 
// GestureRecognizerState.js
// Cacao
// 
// Created on 6/2/23
// 

class GestureRecognizerState {
  
  static possible = 0;
  static began = 1;
  static changed = 2;
  static ended = 3;
  static cancelled = 4;
  static failed = 5;
  static recognized = GestureRecognizerState.ended;
  
}

export default GestureRecognizerState;

