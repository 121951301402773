// 
// Path2D.js
// Cacao
// 
// Created on 9/9/22
// 

import { Rect } from "./Geometry";

class _Path2D extends Path2D {
  
  roundedRect(x, y, width, height, cornerRadius, roundedCornerStyle){
    const rect = Rect.make(x, y, width, height);
    const { minX, minY, maxX, maxY } = rect;
    
    switch (roundedCornerStyle) {
      case "continous":
        const limit = Math.min(width, height) / 2 / 1.52866483;
        const limitedRadius = Math.min(cornerRadius, limit);
        
        const TOP_LEFT = (x, y) => {
          return [ minX + x * limitedRadius,
                   minY + y * limitedRadius ]; 
        };
        
        const TOP_RIGHT = (x, y) => {
          return [ maxX - x * limitedRadius,
                   minX + y * limitedRadius ];
        };
        
        const BOTTOM_RIGHT = (x, y) => {
          return [ maxX - x * limitedRadius,
                   maxY - y * limitedRadius ];
        };
        
        const BOTTOM_LEFT = (x, y) => {
          return [ minX + x * limitedRadius,
                   maxY - y * limitedRadius ];
        };
        
        const { moveTo, lineTo, bezierCurveTo } = this;
        
        moveTo.apply(this, TOP_LEFT(1.52866483, 0.00000000));
        lineTo.apply(this, TOP_RIGHT(1.52866471, 0.00000000));
        bezierCurveTo.apply(this, [].concat(
          TOP_RIGHT(1.08849323, 0.00000000),
          TOP_RIGHT(0.86840689, 0.00000000),
          TOP_RIGHT(0.66993427, 0.06549600)
          )
        );
        
        lineTo.apply(this, TOP_RIGHT(0.63149399, 0.07491100));
        bezierCurveTo.apply(this, [].concat(
          TOP_RIGHT(0.37282392, 0.16905899),
          TOP_RIGHT(0.16906013, 0.37282401),
          TOP_RIGHT(0.07491176, 0.63149399)
          )
        );
        
        bezierCurveTo.apply(this, [].concat(
          TOP_RIGHT(0.00000000, 0.86840701),
          TOP_RIGHT(0.00000000, 1.08849299),
          TOP_RIGHT(0.00000000, 1.52866483)
          )
        );
        
        lineTo.apply(this, BOTTOM_RIGHT(0.00000000, 1.52866471));
        
        bezierCurveTo.apply(this, [].concat(
          BOTTOM_RIGHT(0.00000000, 1.08849323),
          BOTTOM_RIGHT(0.00000000, 0.86840689),
          BOTTOM_RIGHT(0.06549569, 0.66993493)
          )
        );
        
        lineTo.apply(this, BOTTOM_RIGHT(0.07491111, 0.63149399));
        
        bezierCurveTo.apply(this, [].concat(
          BOTTOM_RIGHT(0.16905883, 0.37282392),
          BOTTOM_RIGHT(0.37282392, 0.16905883),
          BOTTOM_RIGHT(0.63149399, 0.07491111)
          )
        );
        
        bezierCurveTo.apply(this, [].concat(
          BOTTOM_RIGHT(0.86840689, 0.00000000),
          BOTTOM_RIGHT(1.08849323, 0.00000000),
          BOTTOM_RIGHT(1.52866471, 0.00000000)
          )
        );
        
        lineTo.apply(this, BOTTOM_LEFT(1.52866483, 0.00000000));
        
        bezierCurveTo.apply(this, [].concat(
          BOTTOM_LEFT(1.08849299, 0.00000000),
          BOTTOM_LEFT(0.86840701, 0.00000000),
          BOTTOM_LEFT(0.66993397, 0.06549569)
          )
        );
        
        lineTo.apply(this, BOTTOM_LEFT(0.63149399, 0.07491111));
        
        bezierCurveTo.apply(this, [].concat(
          BOTTOM_LEFT(0.37282401, 0.16905883),
          BOTTOM_LEFT(0.16906001, 0.37282392),
          BOTTOM_LEFT(0.07491100, 0.63149399)
          )
        );
        
        bezierCurveTo.apply(this, [].concat(
          BOTTOM_LEFT(0.00000000, 0.86840689),
          BOTTOM_LEFT(0.00000000, 1.08849323),
          BOTTOM_LEFT(0.00000000, 1.52866471)
          )
        );
        
        lineTo.apply(this, TOP_LEFT(0.00000000, 1.52866483));
        
        bezierCurveTo.apply(this, [].concat(
          TOP_LEFT(0.00000000, 1.08849299),
          TOP_LEFT(0.00000000, 0.86840701),
          TOP_LEFT(0.06549600, 0.66993397)
          )
        );
        
        lineTo.apply(this, TOP_LEFT(0.07491100, 0.63149399));
        
        bezierCurveTo.apply(this, [].concat(
          TOP_LEFT(0.16906001, 0.37282401),
          TOP_LEFT(0.37282401, 0.16906001),
          TOP_LEFT(0.63149399, 0.07491100)
          )
        );
        
        bezierCurveTo.apply(this, [].concat(
          TOP_LEFT(0.86840701, 0.00000000),
          TOP_LEFT(1.08849299, 0.00000000),
          TOP_LEFT(1.52866483, 0.00000000)
          )
        );
        break;
        
      default:
        if (width < 2 * cornerRadius) cornerRadius = width / 2;
        if (height < 2 * cornerRadius) cornerRadius = height / 2;
        
        this.moveTo(x + cornerRadius, minY);
        this.arcTo(maxX, minY, maxX, maxY, cornerRadius);
        this.arcTo(maxX, maxY, minX, maxY, cornerRadius);
        this.arcTo(minX, maxY, minX, minY, cornerRadius);
        this.arcTo(minX, minY, maxX, minY, cornerRadius);
        
        break;
    }
    this.closePath();
  }
  
}

export default _Path2D;
