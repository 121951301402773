// 
// TapGestureRecognizer.js
// Cacao
// 
// Created on 6/8/23
// 

import GestureRecognizer from "./GestureRecognizer.js"
import State from "./GestureRecognizerState.js"

class TapGestureRecognizer extends GestureRecognizer {
  
  numberOfTapsRequired = 1;
  numberOfTouchesRequired = 1;
  
  canPreventGestureRecognizer(preventingGestureRecognizer){
    if (preventingGestureRecognizer instanceof TapGestureRecognizer) {
      return preventingGestureRecognizer.numberOfTapsRequired > this.numberOfTapsRequired;
    } else {
      return super.canPreventGestureRecognizer(preventingGestureRecognizer);
    }
  }
  
  canBePreventedByGestureRecognizer(preventedGestureRecognizer){
    if (preventedGestureRecognizer instanceof TapGestureRecognizer) {
      return preventedGestureRecognizer.numberOfTapsRequired <= this.numberOfTapsRequired;
    } else {
      return super.canBePreventedByGestureRecognizer(preventedGestureRecognizer);
    }
  }
  
  began(touches){
    const touch = touches[0];
    
    if (touch.tapCount >= this.numberOfTapsRequired) {
      if (this.state == State.possible) {
        this.state = State.began;
      } else if (this.state == State.began) {
        this.state = State.changed;
      }
    }
  }
  
  moved(touches){
    if (this.state == State.began || this.state == State.changed) {
      this.state = State.cancelled;
    }
  }
  
  ended(touches){
    if (this.state == State.began || this.state == State.changed) {
      this.state = State.ended;
    }
  }
  
  cancelled(touches){
    if (this.state == State.began || this.state == State.changed) {
      this.state = State.cancelled;
    }
  }
  
}

export default TapGestureRecognizer;
