// 
// Layout.js
// portfolio
// 
// Created on 5/27/23
// 

import { Rect, Point, Size, Interpolation, RubberBanding } from "cacao/graphics";
const { lerp, invlerp, clamp } = Interpolation;

class CaptionPositionMode {
  static right = 1
  static anchorToBottom = 2
}

class TimelineLayoutMode {
  static regular = 1;
  static compressed = 2;
}

class TimelineLayoutOptions {
  
  // A Size for the regular presentation for images.
  maximumRegularImageSize
  
  // A Number for the caption width, shown on regular presentation.
  minimumCaptionWidth
  
  // A Number for the spacing between image and caption.
  captionSpacing
  
  // A CaptionPositionMode determining how to position the caption
  captionPositioningMode
  
  // A EdgeInsets value determining spacing for the layout
  contentInset
  
  // A Size for the compressed presentation for images.
  maximumCompressedImageSize
  
  // A Number for spacing the images at minimum, used on compressed presentation.
  minimumInterSpacing
  
  // A Size for the current bounds, that will determine the full-screen size for each page on regular presentation.
  boundsSize
  
}

class LayoutAttributes {
  // The index for the represented item.
  itemIndex;
  
  // A Rect to frame the item.
  frame;
  
  // A Rect to frame the image.
  frameForImage;
  
  // A Rect to frame the caption.
  frameForCaption;
  
  // A Number for the alpha for the caption.
  alphaForCaption;
  
  // A transform matrix.
  transform;
  
  constructor(itemIndex){
    this.itemIndex = itemIndex;
  }
  
  copy(){
    const copy = new LayoutAttributes();
    copy.itemIndex = this.itemIndex;
    copy.frame = Rect.from(this.frame);
    copy.frameForImage = Rect.from(this.frameForImage);
    copy.frameForCaption = Rect.from(this.frameForCaption);
    copy.alphaForCaption = this.alphaForCaption;
    copy.transform = DOMMatrix.fromMatrix(this.transform);
    
    return copy;
  }
}

class TimelineLayoutInteractiveTransition {
  origin;
  translation;
  focusedIndex;
  targetMode;
  minimumTranslationForMovement;
  
  transitioning;
  
  constructor(layout){
    this.layout = layout;
    this.translation = Point.zero;
  }
  
  updateInteractiveMovement(parameters){
    const {
      translation = { x: 0, y: 0},
      
    } = parameters;
    
    this.translation = translation;
    
    if (!this.didMoveEnough) {
      const { minimumTranslationForMovement: minimum } = this;
      if (minimum > 0) {
        const passed = (Math.abs(translation.x) > minimum) && !(Math.abs(translation.y) > minimum);
        
        if (!passed) {
          this.translation = { x: 0, y: 0 };
        } else {
          this.didMoveEnough = true;
          this.origin = { x: this.origin.x + translation.x, y: this.origin.y + translation.y };
          this.translationDiff = { x: translation.x, y: translation.y };
          this.translation = { x: 0, y: 0 };
        }
      } else {
        this.didMoveEnough = true;
        this.translationDiff = Point.zero;
      }
      
      if (this.didMoveEnough) {
        const { interactionHasMovement } = this;
        if (interactionHasMovement) {
          interactionHasMovement();
        }
      }
      
    } else {
      const i = this.translationDiff;
      this.translation = { x: translation.x - i.x, y: translation.y - i.y };
    }
  }
  
  setTransitionProgress({ x, y } = Point.zero, parameters){
    const {
      focusedItemOrigin = { x: 0, y: 0 },
      translation = { x: 0, y: 0 },
      targetOffset = { x: 0, y: 0 }
    } = parameters;
    
    this.transitioning = { progressVector: { x, y }, focusedItemOrigin, translation, targetOffset };
  }
  
  complete(finished){
    this.layout._interactiveTransition = undefined;
    
    if (finished) {
      this.layout._mode = this.targetMode;
    }
  }
  
}

class TimelineLayoutInvalidation {
  static get all(){
    const invalidation = new TimelineLayoutInvalidation();
    invalidation.calculateRegularAttributes = true;
    
    return invalidation;
  };
  
  // The regular attributes need to be recalculated.
  calculateRegularAttributes = false;
}

class TimelineLayout {
  // A TimelineLayoutOptions value with the options for configuring the layout.
  _options;
  
  // A TimelineLayoutDelegate object that provides layout info.
  delegate;
  
  // The current transition object.
  _interactiveTransition;
  
  // The current mode.
  _mode;
  
  // Returns a TimelineLayoutMode that represents the current mode.
  get currentMode(){
    return this._mode;
  }
  
  // Returns an array of LayoutItems that represent the layout.
  get layoutAttributes(){
    return this._layoutAttributes;
  }
  
  // Returns a Size for fitting the content.
  get contentSize(){
    return Size.from(this._contentSize);
  }
  
  contentSizeForMode(mode){
    if (mode == TimelineLayoutMode.regular) {
      return this._regularContentSize;
    } else if (mode == TimelineLayoutMode.compressed) {
      return this._compressedContentSize;
    } else {
      return Size.zero;
    }
  }
  
  finalLayoutAttributesForItem(itemIndex, mode){
    if (!mode) {
      mode = this._mode;
    }
    
    if (mode == TimelineLayoutMode.regular) {
      return this._regularLayoutAttributes[itemIndex];
    } else if (mode == TimelineLayoutMode.compressed) {
      return this._compressedLayoutAttributes[itemIndex];
    } else {
      return undefined;
    }
  }
  
  constructor(options, delegate){
    this._options = options;
    this._mode = TimelineLayoutMode.compressed;
    this._invalidation = TimelineLayoutInvalidation.all;
    
    this.delegate = delegate;
  }
  
  set options(options){
    this._options = options;
    this._invalidation = TimelineLayoutInvalidation.all;
  }
  
  get options(){
    return this._options;
  }
  
  // Begins a transition starting on the specified Point
  beginInteractiveTransition(origin, { minimumTranslationForMovement = 0 } = {}){
    if (this._interactiveTransition) {
      throw new Error("An interactive transition is in place.");
    }
    
    const transition = new TimelineLayoutInteractiveTransition(this);
    transition.minimumTranslationForMovement = minimumTranslationForMovement;
    transition.targetMode = (this._mode == TimelineLayoutMode.regular) ? TimelineLayoutMode.compressed : TimelineLayoutMode.regular;
    transition.origin = origin;
    transition.focusedIndex = (this.layoutAttributes.length > 0) ? 0 : null;
    
    const currentLayoutAttributes = this.layoutAttributes, count = currentLayoutAttributes.length;
    
    for (let idx = 0; idx < count; idx += 1) {
      const attributes = currentLayoutAttributes[count - idx - 1];
      if (origin.y > attributes.frame.minY) {
        transition.focusedIndex = attributes.itemIndex;
        break;
      }
    }
    
    this._interactiveTransition = transition;
    
    return transition;
  }
  
  // Begins a transition from the specified item:
  beginInteractiveTransitionCenteredOnItem(item){
    const { frame } = this.layoutAttributes[item];
    const center = Point.make(frame.midX, frame.midY);
    const transition = this.beginInteractiveTransition(center);
    transition.focusedIndex = item;
    
    return transition;
  }
  
  cancelInteractiveTransition(){
    if (this._interactiveTransition) {
      this._interactiveTransition.complete(false);
    }
  }
  
  // Performs the layout.
  layout(){
    const { _invalidation: invalidation } = this; 
    
    if (invalidation.calculateRegularAttributes) {
      this._calculateRegularAttributes();
      this._calculateCompressedAttributes();
      
      invalidation.calculateRegularAttributes = false;
    }
    
    this._layoutAttributes = this._regularLayoutAttributes;
    this._contentSize = this._regularContentSize;
    
    if (this._interactiveTransition) {
      if (this._interactiveTransition.transitioning) {
        this._layoutForInteractionCompletion();
        this._contentSize = this.contentSizeForMode(this._interactiveTransition.targetMode);
      } else {
        this._layoutForInteraction();
      }
    } else {
      if (this._mode == TimelineLayoutMode.compressed) {
        this._layoutAttributes = this._compressedLayoutAttributes;
        this._contentSize = this._compressedContentSize;
      }
    }
    
  }
  
  _calculateRegularAttributes(){
    const layoutAttributes = [];
    const { options, delegate } = this;
    
    const bounds = Rect.make(0, 0, options.boundsSize.width, options.boundsSize.height);
    const contentRect = bounds.insetByEdgeInsets(options.contentInset);
    
    const { numberOfItems } = delegate;
    
    let offsetY = 0.0;
    
    for (let item = 0; item < numberOfItems; item += 1) {
      const maximumImageSize = Size.from(options.maximumRegularImageSize);
      
      maximumImageSize.height = Math.min(maximumImageSize.height, contentRect.height);
      
      const captionSize = Size.make(options.minimumCaptionWidth, 0);
      const captionReducesImageHeight = (options.captionPositioningMode == CaptionPositionMode.anchorToBottom);
      
      if (captionReducesImageHeight) {
        captionSize.width = contentRect.width;
        captionSize.height = this.delegate.captionHeightForItem(item, captionSize.width);
        
        const height = (contentRect.height - captionSize.height - options.captionSpacing);
        
        if (height < maximumImageSize.height) {
          maximumImageSize.height = height;
        }
      }
      
      const imageSize = delegate.imageSizeForItem(item);
      const scaleX = maximumImageSize.width / imageSize.width;
      const scaleY = maximumImageSize.height / imageSize.height;
      const imageScale = Math.min(scaleX, scaleY);
      const scaledImageSize = Size.make(Math.floor(imageSize.width * imageScale), Math.floor(imageSize.height * imageScale));
      
      const imageRect = new Rect(Point.zero, scaledImageSize);
      const captionRect = new Rect(Point.zero, captionSize);
      
      let itemSize;
      
      if (options.captionPositioningMode == CaptionPositionMode.right) {
        captionRect.origin.x = imageRect.maxX + options.captionSpacing;
        captionRect.size.height = imageRect.height;
        
        itemSize = Size.make(captionRect.maxX, imageRect.height);
        
      } else if (options.captionPositioningMode == CaptionPositionMode.anchorToBottom) {
        imageRect.origin.x = Math.round(contentRect.width / 2.0) - Math.round(imageRect.width / 2.0);
        imageRect.origin.y = Math.round((contentRect.height - captionSize.height - options.captionSpacing) / 2.0) - Math.round(imageRect.height / 2.0);
        
        captionRect.origin.y = contentRect.height - captionSize.height;
        
        itemSize = Size.from(contentRect.size);
      }
      
      const origin = Point.zero;
      origin.x = Math.min(contentRect.midX - Math.round(itemSize.width / 2.0), contentRect.maxX - itemSize.width);
      origin.y = offsetY + contentRect.midY - Math.round(itemSize.height / 2.0);
      
      const attributes = new LayoutAttributes(item);
      attributes.frame = new Rect(origin, itemSize);
      attributes.frameForImage = imageRect;
      attributes.frameForCaption = captionRect;
      attributes.alphaForCaption = 1.0;
      attributes.transform = new DOMMatrix();
      
      layoutAttributes.push(attributes);
      
      offsetY += bounds.height;
    }
    
    this._regularLayoutAttributes = layoutAttributes;
    this._regularContentSize = Size.make(bounds.width, Math.ceil(offsetY));
  }
  
  _calculateCompressedAttributes(){
    const { options } = this;
    
    const bounds = Rect.make(0, 0, options.boundsSize.width, options.boundsSize.height);
    const contentRect = bounds.insetByEdgeInsets(options.contentInset);
    
    const maximum = options.maximumRegularImageSize;
    const minimum = options.maximumCompressedImageSize;
    
    const preferedWidthToFillContentRect = contentRect.width * 0.45;
    
    const minifiedWidth = Math.max(minimum.width, preferedWidthToFillContentRect);
    const minificationScale = minifiedWidth / maximum.width;
    
    const interSpacing = Math.max(options.minimumInterSpacing, minifiedWidth / 5);
    
    const scaledCaptionWidth = (options.captionPositioningMode == CaptionPositionMode.right) ? (options.minimumCaptionWidth + options.captionSpacing) * minificationScale : 0.0;
    
    const scaledHeightForItems = this._regularLayoutAttributes
    .map((attributes) => attributes.frameForImage.height * minificationScale)
    .reduce((a, c) => a + c, interSpacing * (this._regularLayoutAttributes.length - 1));
    
    let offsetY = contentRect.minY;
    
    if (contentRect.height > scaledHeightForItems) {
      offsetY = contentRect.minY + Math.round(contentRect.height - scaledHeightForItems) / 2.0;
    }
    
    const largestScaledImageWidth = this._regularLayoutAttributes.reduce((r, b) => Math.max(r, b.frameForImage.width), Number.NEGATIVE_INFINITY) * minificationScale;
    
    const alignmentOriginX = contentRect.maxX - Math.round(largestScaledImageWidth * 0.5);
    
    this._compressedLayoutAttributesScale = minificationScale;
    this._compressedLayoutAttributes = this._regularLayoutAttributes.map(attributes => {
      const isLastItem = (attributes === this._regularLayoutAttributes.at(-1));
      
      attributes = attributes.copy();
      
      const adjustedFrame = attributes.frame.copy();
      const size = adjustedFrame.size;
      const scaledSize = Size.make(size.width * minificationScale, size.height * minificationScale);
      
      adjustedFrame.origin.x = alignmentOriginX - Math.round((scaledSize.width - scaledCaptionWidth) * 0.5);
      adjustedFrame.origin.y = offsetY - (attributes.frameForImage.minY * minificationScale);
      
      attributes.frame = adjustedFrame;
      attributes.transform = new DOMMatrix().scale(minificationScale);
      attributes.alphaForCaption = 0;
      
      const scaledImageHeight = attributes.frameForImage.height * minificationScale;
      
      offsetY += scaledImageHeight + (isLastItem ? options.contentInset.bottom : interSpacing);
      
      return attributes;
    });
    
    this._compressedContentSize = Size.make(bounds.width, Math.max(Math.ceil(offsetY), bounds.height));
  }
  
  _layoutForInteraction(){
    const transition = this._interactiveTransition;
    let { translation, origin, focusedIndex } = transition;
    
    const offset = Point.from(translation);
    
    let targetOffsetX;
    let fromScale, toScale;
    let fromAttributes, toAttributes;
    
    if (transition.targetMode == TimelineLayoutMode.compressed) {
      const attributes = this._regularLayoutAttributes[focusedIndex];
      const { minX, maxX } = attributes.frame;
      
      // Constraint origin:
      origin.x = Math.min(Math.max(minX, origin.x), maxX);
      
      // Offset inside mix:
      const originPastMinX = Math.max(origin.x - minX, 0) * this._compressedLayoutAttributesScale;
      
      targetOffsetX = Math.abs((origin.x - originPastMinX) - this._compressedLayoutAttributes[focusedIndex].frame.minX);
      fromScale = 1;
      toScale = this._compressedLayoutAttributesScale;
      fromAttributes = this._regularLayoutAttributes;
      toAttributes = this._compressedLayoutAttributes;
      
    } else {
      const compressedAttributes = this._compressedLayoutAttributes[focusedIndex];
      const compressedScale = this._compressedLayoutAttributesScale;
      
      const imageRectMinX = compressedAttributes.frame.minX; //+ (compressedAttributes.frameForImage.minX * compressedScale);
      const imageRectMaxX = imageRectMinX + (compressedAttributes.frameForImage.width * compressedScale);
      
      // Constraint origin between `imageRectMinX` and `imageRectMaxX`
      origin.x = Math.min(Math.max(imageRectMinX, origin.x), imageRectMaxX);
      
      // Fix-me: Forced origin:
      if (this.options.captionPositioningMode == CaptionPositionMode.anchorToBottom) {
        origin.x = compressedAttributes.frame.minX;
      }
      
      // Scale up the offset after `imageRectMinX`
      const scaledCompressedBoundsOffsetX = Math.max(origin.x - imageRectMinX, 0) * (1 / compressedScale);
      
      targetOffsetX = Math.abs((origin.x - scaledCompressedBoundsOffsetX) - this._regularLayoutAttributes[focusedIndex].frame.minX) * -1;
      fromScale = this._compressedLayoutAttributesScale;
      toScale = 1;
      fromAttributes = this._compressedLayoutAttributes;
      toAttributes = this._regularLayoutAttributes;
    }
    
    // Rubber banding:
    const restrictsWithRubberBanding = true;
    if (restrictsWithRubberBanding) {
      const sign = (transition.targetMode == TimelineLayoutMode.regular) ? 1 : -1;
      const dim = targetOffsetX * -sign;
      
      offset.x = clampedRubberBanding(offset.x * sign, targetOffsetX * sign, 0, { dim }) * sign;
    }
    
    const progress = clamp(invlerp(0, targetOffsetX, offset.x));
    const scale = lerp(fromScale, toScale, progress);
    
    let focusedItemDisplacement = 0.0;
    
    this._layoutAttributes = fromAttributes.map((attributes, index) => {
      const o = attributes;
      attributes = attributes.copy();
      
      const target = toAttributes[index];
      attributes.frame = interpolateRect(attributes.frame, target.frame, progress, progress);
      
      if (attributes.itemIndex == focusedIndex) {
        const m = new DOMMatrix()
        .translate(origin.x, origin.y)
        .translate(offset.x, offset.y)
        .scale(1 / fromScale)
        .scale(scale)
        .translate(-origin.x, -origin.y)
        
        const point = new DOMPoint(o.frame.origin.x, o.frame.origin.y).matrixTransform(m);
        
        const computedOriginY = attributes.frame.minY;
        attributes.frame.origin = Point.make(point.x, point.y);
        
        focusedItemDisplacement = (computedOriginY - attributes.frame.minY);
      }
      
      attributes.transform = new DOMMatrix().scale(scale);
      
      if (index == focusedIndex) {
        attributes.alphaForCaption = lerp(attributes.alphaForCaption, target.alphaForCaption, progress);
      } else {
        attributes.alphaForCaption = 0.0;
      }
      
      return attributes;
    });
    
    this._layoutAttributes = this._layoutAttributes.map((attributes) => {
      if (attributes.itemIndex != focusedIndex) {
        attributes.frame.origin.y -= focusedItemDisplacement;
      }
      return attributes;
    });
    
    this._interactiveScale = scale;
    this._interactiveLayoutAttributes = this._layoutAttributes;
  }
  
  _layoutForInteractionCompletion(){
    const transition = this._interactiveTransition;
    
    const { focusedIndex } = transition;
    const { progressVector, focusedItemOrigin, targetOffset, translation } = transition.transitioning;
    
    if (!this._interactiveLayoutAttributes) {
      this._layoutForInteraction();
    }
    
    const fromAttributes = this._interactiveLayoutAttributes;
    let targetAttributes, fromScale = this._interactiveScale, toScale;
    
    if (transition.targetMode == TimelineLayoutMode.compressed) {
      targetAttributes = this._compressedLayoutAttributes;
      toScale = this._compressedLayoutAttributesScale;
    } else {
      targetAttributes = this._regularLayoutAttributes;
      toScale = 1;
    }
    
    const progressX = clampedRubberBanding(progressVector.x, 0, 1, { dim: 1 });
    const progressY = clampedRubberBanding(progressVector.y, 0, 1, { dim: 1 });
    
    const offset = Point.make(
      lerp(translation.x, targetOffset.x, progressX),
      lerp(translation.y, targetOffset.y, progressY)
    );
    
    const overallProgress = (progressX + progressY) / 2;
    const scale = lerp(fromScale, toScale, overallProgress);
    
    this._layoutAttributes = this._layoutAttributes.map((attributes, index) => {
      attributes = attributes.copy();
      
      const initial = fromAttributes[index];
      const target = targetAttributes[index];
      
      attributes.frame = interpolateRect(initial.frame, target.frame, progressX, progressY);
      attributes.transform = new DOMMatrix().scale(scale);
      
      if (index == focusedIndex) {
        attributes.frame.origin = Point.from(focusedItemOrigin);
        attributes.alphaForCaption = lerp(initial.alphaForCaption, target.alphaForCaption, overallProgress);
      } else {
        attributes.frame = attributes.frame.offsetBy(offset.x, offset.y);
        attributes.alphaForCaption = 0.0;
      }
      
      return attributes;
    });
    
  }
  
}

function clampedOffset(target, lowerBound, upperBound){
  if (target < lowerBound) {
    const diff = lowerBound - target;
    const dim = Math.abs(lowerBound);
    
    return lowerBound - RubberBanding.clamp(diff, { dim });
  } else if (target > upperBound) {
    const boundsDimension = Math.abs(lowerBound - upperBound);
    
    const diff = target - upperBound;
    const dim = Math.abs(boundsDimension - upperBound);
    
    return upperBound + RubberBanding.clamp(diff, { dim });
  } else {
    return target;
  }
};

function interpolateRect(a, b, progress, progressY){
  return Rect.make(
    lerp(a.origin.x, b.origin.x, progress),
    lerp(a.origin.y, b.origin.y, progressY),
    lerp(a.size.width, b.size.width, progress),
    lerp(a.size.height, b.size.height, progressY)
  );
};

function clampedRubberBanding(target, lowerBound, upperBound, { dim } = {}){
  let clampedTarget = Math.min(Math.max(target, lowerBound), upperBound);
  let diff = Math.abs(target - clampedTarget);
  let sign = clampedTarget > target ? -1 : 1;
  return clampedTarget + sign * RubberBanding.clamp(diff, { dim });
};

export {
  TimelineLayoutOptions,
  TimelineLayoutMode,
  CaptionPositionMode,
  TimelineLayout
};
