// 
// PageContainerView.js
// portfolio
// 
// Created on 6/22/23
//

import { View, PanGestureRecognizer, GestureRecognizerState, TouchType } from "cacao/ui";
import { Size, Point } from "cacao/graphics";
import ScrollEngine from "../scrolling/ScrollEngine";

class PageContainerTransitioning {
  static animate = "animate";
  static willAppear = "will-appear";
  static willDisappear = "will-disappear";
  static willDisappearInteractive = "will-disappear-interactive";
}

class PageContainerView extends View {
  wrapperView;
  animator;
  transition;
  
  _contentView;
  _navigationContainerView;
  
  get contentView(){
    return this._contentView;
  }
  
  get navigationContainerView(){
    return this._navigationContainer;
  }
  
  constructor(contentView){
    super();
    this.node.className = "page-container";
    this.polyfillIfNeeded();
    
    // Events:
    this.node.addEventListener("transitionend", this);
    this.node.addEventListener("cancel", this);
    
    // Wrapper view:
    const wrapperContainerView = new View();
    wrapperContainerView.node.className = "page-container-wrapper";
    wrapperContainerView.node.style.touchAction = "zoom";
    
    const wrapperView = new View();
    wrapperView.node.className = "page-container-content";
    
    wrapperContainerView.addSubview(wrapperView);
    
    this.addSubview(wrapperContainerView);
    
    wrapperView.addSubview(contentView);
    
    this.wrapperView = wrapperContainerView;
    this._contentView = contentView;
    
    // Navigation container:
    const navigationContainerView = new View();
    navigationContainerView.node.className = "page-container-navigation";
    
    this._navigationContainer = navigationContainerView;
    
    this.addSubview(navigationContainerView);
    
    // Gestures:
    this.addInteractions();
  }
  
  makeNode(document){
    return document.createElement("dialog");
  }
  
  addInteractions(){
    const didPan = () => {
      const { state, translation, velocity } = panGestureRecognizer;
      
      switch (state){
        case GestureRecognizerState.began:
          const { clientWidth, clientHeight} = this.wrapperView.node;
          
          if (!this.animator){
            this.animator = new ScrollEngine();
          }
          
          const animator = this.animator;
          animator.boundsSize = Size.make(clientWidth, clientHeight);
          animator.contentSize = Size.make(clientWidth, clientHeight * 2.0);
          animator.anchorLimits = [ Point.zero, Point.make(0, -clientHeight )];
          animator.startDragging();
          
          this.animator = animator;
        
          break;
        case GestureRecognizerState.changed:
          this.animator.continueDragging(translation.x, translation.y * -1.0);
          this.configureWrapper({ contentOffset: this.animator.contentOffset });
          
          break;
        case GestureRecognizerState.ended:
          this.decelerate({ velocity });
          break;
        case GestureRecognizerState.cancelled:
          // TODO.
          break;
      }
    };
    
    
    const shouldBegin = (gestureRecognizer) => {
      const isTouchTypeDirect = gestureRecognizer.touchesInView(this)[0].type == TouchType.direct;
      if (isTouchTypeDirect) {
        return false;
      }
      return this.transition == undefined;
    };
    
    const panGestureRecognizer = new PanGestureRecognizer();
    panGestureRecognizer.didChange = didPan;
    panGestureRecognizer.shouldBegin = shouldBegin;
    
    panGestureRecognizer.attach(this.wrapperView.node);
  }
  
  configureWrapper({ contentOffset } = {}){
    const { wrapperView } = this;
    const { node } = wrapperView;
    
    const transform = new DOMMatrix().translate(contentOffset.x, contentOffset.y * -1.0);
    node.style.transform = transform;
  }
  
  decelerate({ velocity } = {}){
    const { animator } = this;
    
    velocity = Point.from(velocity);
    velocity.y *= -1.0;
    
    let dismissing = false;
    
    animator.endDragging({ decelerationVelocity: velocity }, {
      willAnimate: (targetContentOffset) => {
        dismissing = (targetContentOffset.y != 0.0);
        
        if (dismissing) {
          const { node } = this;
          const transition  = { name: PageContainerTransitioning.willDisappearInteractive };
          this.transition = transition;
          
          node.classList.add(transition.name);
          
          window.requestAnimationFrame(() => {
            node.classList.add(PageContainerTransitioning.animate);
          });
        }
      },
      
      didAnimate: () => {
        const { contentOffset } = animator;
        this.configureWrapper({ contentOffset });
      },
      
      didFinish: () => {
        if (dismissing){
          this._remove();
        }
      }
    });
  }
  
  handleEvent(event){
    if (event.target != this.node) {
      return;
    }
    
    switch (event.type){
      case "transitionend":
        const { transition } = this;
        
        if (transition) {
          this.node.classList.remove(transition.name, PageContainerTransitioning.animate);
          
          if (transition.name == PageContainerTransitioning.willDisappear || 
              transition.name == PageContainerTransitioning.willDisappearInteractive) {
            this._remove();
          }
          this.transition = undefined;
        }
        break;
      case "cancel":
        event.preventDefault();
        this.dismiss();
        break;
      default:
        break;
    }
  }
  
  presentInView(presentingView, { animated = true } = {}){
    if (this.transition) {
      console.warn("Skipping `presentInView()`, there's an ongoing transition:", this.transition);
      return; 
    }
    
    const { node: containerNode } = presentingView;
    const { node } = this;
    
    containerNode.appendChild(node);
    
    if (animated) {
      const transition = { name: PageContainerTransitioning.willAppear };
      this.transition = transition;
      
      window.requestAnimationFrame(() => {
        node.classList.add(transition.name);
        node.showModal();
        
        window.requestAnimationFrame(() => {
          node.classList.add(PageContainerTransitioning.animate);
        });
      });
      
    } else {
      window.requestAnimationFrame(() => node.showModal());
    }
  }
  
  dismiss({ animated = true } = {}){
    if (this.transition) {
      console.warn("Skipping `dismiss()`, there's an ongoing transition:", this.transition);
      return; 
    }
    
    if (animated) {
      const { node } = this;
      const transition  = { name: PageContainerTransitioning.willDisappear };
      this.transition = transition;
      
      window.requestAnimationFrame(() => {
        node.classList.add(transition.name);
        
        window.requestAnimationFrame(() => {
          node.classList.add(PageContainerTransitioning.animate);
        });
      });
      
    } else {
      this._remove();
    }
  }
  
  _remove(){
    this.node.close();
    this.removeFromSuperview();
    
    const { didDisappear } = this;
    if (didDisappear) {
      didDisappear();
    }
  }
  
  polyfillIfNeeded(){
    const { node } = this;
    const { style } = node;
    
    const openAttributeName = "open";
    
    if (!node.showModal) {
      node.showModal = () => {
        node.setAttribute(openAttributeName, "");
        style.display = undefined;
        style.top = "0px";
        style.left = "0px";
        style.width = "100%";
        style.height = "100%";
        style.position = "fixed";
        style.zIndex = "999";
      };
    }
    
    if (!node.close) {
      node.close = () => {
        node.removeAttribute(openAttributeName);
        node.style.display = "none";
      };
    }
  }
  
}


export default PageContainerView;
