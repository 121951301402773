// 
// ScrollEngineDecelerationRate.js
// portfolio
// 
// Created on 6/20/23
// 

class ScrollEngineDecelerationRate {
  
  // UIScrollViewDecelerationRateNormal
  static normal = 0.998;
  
  // UIScrollViewDecelerationRateFast
  static fast = 0.99;
  
}

export default ScrollEngineDecelerationRate;
