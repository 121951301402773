## 
## content.yaml
## portfolio
## 
## Created on 6/24/23
## 

header:
  title: Matías Martínez
  year: 2023
  description: I’m a fellow human from Chile.
  keywords: designer, developer, Cornershop, Uber, Chile, Mexico, Latin America, startups, UI, UX, product design, portfolio, work, iOS, iPhone, iPad, macOS, JS
  preview: /res/img/preview.jpg

navigation:
  - title: Who?
    url: /about/
    inline: true
  
  - title: Instagram
    url: https://www.instagram.com/matmartinez/
  
  - title: Twitter
    url: https://twitter.com/martinez

featurette:
  title: Work inquiries?
  subtitle: Mantisa
  url: https://mantisa.com/?ref=matias.me
  image:
    src: mantisa.svg
    size: [ 46, 40 ]
    alt: Mantisa
  
footer: |
  © 2009-2024

work:
  - title: Chile Vaccination Card for Apple Wallet
    role: Maker
    year: 2022
    
    action:
      title: Add to Wallet
      url: https://pase.pro/
    
    image:
      src: pase-pro.png
      size: [ 405, 645 ]
      alt: Apple Watch displaying a chilean vaccination card.
  
  - title: Figurative for iPad
    role: Maker
    year: 2021
    
    action:
      title: Get
      url: https://figurative.design/
    
    image:
      src: figurative.png
      size: [ 718, 572 ]
      alt: iPad Pro with a keyboard case attached displaying Figma.
  
  - title: Uber Grocery
    role: Product Design
    company: Cornershop (adquired by Uber)
    year: 2020
    
    image:
      src: uber-grocery.png
      size: [ 424, 857 ]
      alt: iPhone displaying different grocery stores to pick from.
    
    team:
      - Laura Sandoval
      - Angela Wen
      - Angie Kwan
      - Gonzalo Castillo
      - Francisco Yáñez
      - Francisco García
      - Osvaldo Mena
      - Alejandra Fernández
      - Ricardo Oyarzun
      - Matías Martínez
  
  - title: Design Principles
    role: Manager, Co-Author
    company: Cornershop
    year: 2019
    
    image:
      src: cornershop-design-principles.png
      size: [ 480, 660 ]
      alt: iPad Pro displaying a design principles website with illustrations.
    
    team:
      - Samuel Gómez
      - Alegre González
      - Varsha Ashok
      - Gonzalo Castillo
      - Laura Sandoval
      - Nickole Pizarro
      - Cristopher Oyarzún
      - Matías Martínez
  
  - title: MMNumberKeyboard
    role: Design, Development
    year: 2017
    
    image:
      src: mmnumberkeyboard.png
      size: [ 449, 896 ]
      alt: iPhone displaying a simple numeric keyboard on what is seems like a test application.
    
    action:
      title: GitHub
      url: https://github.com/matmartinez/MMNumberKeyboard
  
  - title: “Trojan Avocado”
    role: Concept, Crack-Photoshop Job
    company: Cornershop
    year: 2017
    
    image:
      src: trojan-avocado.png
      size: [ 612, 459 ]
      alt: Framed mythological painting that represents a scene from the Trojan War. Instead of the a wooden horse, there's a giant wooden avocado dragged into the City of Troy.
    
    team:
      - Oskar Hjertonsson
      - Matías Martínez
  
  - title: Cornershop for Web
    role: Product, Design, Development
    company: Cornershop
    year: 2016
    
    image:
      src: cornershop-new-web.png
      size: [ 1018, 596 ]
      alt: MacBook Pro displaying a website for shopping groceries from Superama.
    
    team:
      - Javiera Vasquez
      - Francisco Yáñez
      - Osvaldo Mena
      - Oskar Hjertonsson
      - Daniel Undurraga
      - Matías Martínez
  
  - title: Home
    role: Product, Design, Development
    company: Cornershop
    year: 2016
    
    image:
      src: cornershop-landing.png
      size: [ 842, 493 ]
      alt: MacBook Air displaying a website with big and bold letters in red, typeset in Avenir Next, that reads “Groceries from your doorstep in one hour” on top of a giant tomato.
    
    team:
      - Oskar Hjertonsson
      - Daniel Undurraga
      - Juan Pablo Cuevas
      - Matías Martínez
  
  - title: Shopper for iPhone
    role: Product, Design
    company: Cornershop
    year: 2015
    
    image:
      src: cornershop-shopper-ios.png
      size: [ 435, 888 ]
      alt: iPhone displaying an app for picking groceries. It shows the customer has requested 2 ripe bananas, and there's different options to mark the product as found, offer a replacement, or give a refund.
    
    team:
      - Cristopher Oyarzún
      - Francisco Fuentes
      - Hector Ghinaglia
      - Matías Martínez
  
  - title: Shopper for Android
    role: Product, Design
    company: Cornershop
    year: 2015
    
    image:
      src: cornershop-shopper-android.png
      size: [ 429, 861 ]
      alt: Google Pixel displaying an app for picking groceries. It shows a barcode scanner that is using the device's camera, currently detecting a barcode framed on a green rectangle.
    
    team:
      - Cristopher Oyarzún
      - Francisco Fuentes
      - Hector Ghinaglia
      - Matías Martínez
  
  - title: Shoppers Self-Onboard
    role: Product, Design
    company: Cornershop
    year: 2015
    
    image:
      src: cornershop-shopper-welcome.png
      size: [ 429, 861 ]
      alt: Google Pixel displaying a welcome message. It includes the Cornershop logo and a "We Can Do It!" American World War II wartime poster.
    
    team:
      - Francisco Fuentes
      - Diego Carey
      - Matías Martínez
  
  - title: Cornershop for iPad
    role: Product, Design, Development
    company: Cornershop
    year: 2015
    
    image:
      src: cornershop-ipad.png
      size: [ 480, 678 ]
      alt: An iPad displaying the Cornershop app, showing the storefront of a grocery store. It has a list of aisles with colorful background images on the left, and a grid of products for the selected aisle on the right. An image of lemons is being dragged from the grid.
    
    team:
      - Diego Torres
      - Matías Martínez
      
  - title: Cornershop for iPhone
    role: Product, Design, Development
    company: Cornershop
    year: 2015
    
    image:
      src: cornershop-iphone.png
      size: [ 436, 888 ]
      alt: An iPhone displaying the Cornershop app, showing a pack of bananas. The user interface has lots of white space and buttons are tinted in a shade of red. A toolbar shows that 4 bananas were added into the Cart.
    
    team:
      - Diego Torres
      - Oskar Hjertonsson
      - Daniel Undurraga
      - Chaq Cuevas
      - Cristopher Oyarzún
      - Osvaldo Mena
      - Matías Martínez
      
  - title: Seahorse
    role: User Interface Design, Development
    year: 2014
    
    image:
      src: seahorse-ios7.png
      size: [ 362, 737 ]
      alt: An iPhone displaying an app with a grid of 4 photos. One of the photos is being flipped in 3D space to reveal another photo. A purple selection shows "Flashback" and text "Two years ago today" below the images.
    
    team:
      - Diego Torres
      - Oskar Hjertonsson
      - Daniel Undurraga
      - Cristopher Oyarzún
      - Osvaldo Mena
      - Matías Martínez
      
  - title: Seahorse ☆ iOS 6
    role: User Interface Design, Development
    year: 2013
    
    image:
      src: seahorse-ios6.png
      size: [ 380, 799 ]
      alt: An iPhone displaying an app with a grid of 12 photos in a shared album. The user interface is dark and the photos look vibrant. A purple camera button to add more photos can be seen on a toolbar.
    
    team:
      - Diego Torres
      - Oskar Hjertonsson
      - Daniel Undurraga
      - Cristopher Oyarzún
      - Osvaldo Mena
      - Matías Martínez

  - title: Posterama
    role: Maker
    year: 2013
    
    image:
      src: posterama.png
      size: [ 843, 636 ]
      alt: An Apple Thunderbolt Display is displaying a dark website in fullscreen. It shows a vintage poster from the film "A Hard Day's Night" in Spanish. Another partially visible poster can be seen behind it. 
  
  - title: Controls
    role: Maker
    year: 2012
    
    image:
      src: controls.png
      size: [ 440, 831 ]
      alt: An iPhone 5 is displaying a fullscreen app that resembles a remote control made from aluminum. A directional pad shaped like a ring in black is located at the top. Below it there's a black play and pause black button.
  
  - title: Unnamed Music app
    role: Maker
    year: 2012
    
    image:
      src: albums.png
      size: [ 790, 443 ]
      alt: A MacBook Air is displaying a Music app with Cover Flow and a list of songs. The user interface is themed dark, with a darker shade of aluminum for the window frame.
  
  - title: NSFW
    role: <span aria-label="Corrupted pixels" role="img">▣▨▦▤▣</span>
    year: 2012
    
    image:
      src: nsfw.png
      size: [ 1024, 824 ]
      alt: A 27-inch iMac is displaying a mysterious website in fullscreen. The screen is mostly empty with the exception of a small pink button in the center that reads "Trust me".
    
    action:
      title: Launch
      url: https://matias.me/nsfw
  
  - title: Written
    role: Maker
    year: 2011
    
    image:
      src: written.png
      size: [ 384, 745 ]
      alt: An iPhone 4 is displaying a text editing app with an on-screen keyboard. The screen has a suble paper-like brown texture, and the text is being syntax-highlighted with the Markdown markup language.
      
  - title: Delivery
    role: Maker
    year: 2011
    
    image:
      src: delivery.png
      size: [ 790, 464 ]
      alt: A MacBook Pro is displaying an orange window that reads "Drop source code here". The window has a simple line illustration of two sheets of paper enclosed in dashed line with rounded corners. The Dock shows an icon of a Vespa in the style of Xcode.
  
  - title: Review
    role: Maker
    year: 2010
    
    image:
      src: review.png
      size: [ 772, 660 ]
      alt: An iMac is displaying a text editor app with two panes. A sheet made of wood is presented on the main window, despicting two photorealistic dictionaries the user can choose from. The dock shows an icon of an uppercase and lowercase letter "A". 
      
  - title: AirMail
    role: Developer, User Interface Design
    year: 2009
    
    image:
      src: airmail.png
      size: [ 796, 472 ]
      alt: A MacBook is displaying a notification on the upper-right corner of the screen. It has a paper airplane icon. The same airplane icon shape can be seen in the menu bar, highlighted in blue.
    
    team:
      - Angelo Stazzi
      - Matías Martínez
  
pages:
  /about/:
    title: Who?
    body: |
      <p>Hola! I'm Matías. Growing up, I taught myself how to do computer stuff and discovered that I really enjoy making things. That's mostly it.</p>
        
      <p>I was born in Curicó, Chile. I tried my luck and moved to San Francisco, USA because some crazy guys wanted me to build a social app named <a target="_blank" href="https://techcrunch.com/2014/09/04/seahorse-lets-you-collaborate-with-friends-to-build-lasting-photo-and-video-albums-shared-privately/">Seahorse</a> <em>(spoiler: it was a failure)</em>. I went back to Chile and then got lucky starting the grocery app/service  <a target="_blank" href="https://techcrunch.com/2021/06/21/uber-to-become-the-sole-owner-of-grocery-delivery-startup-cornershop/">Cornershop</a>.</p>
      
      <p>I also like playing piano, videogames, and <a target="_blank" href="https://en.wikipedia.org/wiki/Mazda_MX-5">Miatas</a>—all of which I kinda suck at. I once made a <a target="_blank" href="https://knowyourmeme.com/memes/ditto-conga">meme</a> and it went viral.</p>
      
      <p>Feel free to write me — <a href="mailto:Matías%20Martínez%20<hello@matias.me>?subject=Hola!" alt="Send an email">hello@matias.me</a> <em>(I do suck too at email, but I really love meeting people)</em>.</p>
    
    images:
      - src: computerwelt.jpg
        alt: Me as a 5 year old.
      
      - src: trucker.jpg
        alt: Me driving a Mazda Miata (conceptualized).
      
      - src: long.jpg
        alt: Me (long version).
      
      - src: love.jpg
        alt: My girlfriend and me.
      
      - src: gpoy.jpg
        alt: Me as me.
  
  /404/:
    title: Page not found
    body: |
      <p>It seems like the page you were looking was moved or decided to take a spontaneous vacation. <i>Guru meditation error #0000404</i>.</p>
  
