// 
// TimelineInteractionCompletion.js
// portfolio
// 
// Created on 6/9/23
// 

class TimelineInteractionCompletion {
  type;
  parameters;
  
  constructor(type, parameters){
    this.type = type;
    this.parameters = parameters;
  }
  
  static decelerate({ decelerationVelocity = Point.zero, animated = false } = {}){
    return new this(this.type.decelerate, { decelerationVelocity, animated });
  }
  
  static cancel({ animated = false } = {}) {
    return new this(this.type.cancel, { animated });
  }
  
  static focus({ animated = false } = {}){
    return new this(this.type.focus, { animated });
  }
  
  static unfocus({ animated = false } = {}){
    return new this(this.type.unfocus, { animated });
  }
  
  static get type(){
    return {
      decelerate: 1,
      focus: 2,
      unfocus: 3,
      cancel: 4,
    }
  }
  
}

export default TimelineInteractionCompletion;
