import { App } from "cacao/ui";
import RootView from "./RootView";

import contentRoot from "../content.yaml";
import * as artwork from "../res/pages/*/*";
import * as darkArtwork from "../res/pages/*/dark-color-scheme/*";
import MappedImage from "./resources/MappedImage";

class P2023 extends App {
  
  documentDidLoad(document){
    // Resolve content:
    const mappedContent = [
      // Work:
      { images: contentRoot.work.map($0 => $0.image), key: "work" },
      
      // About page:
      { images: contentRoot.pages["/about/"].images, key: "about" },
      
      // Featurette:
      (contentRoot.featurette) ? { images: [ contentRoot.featurette.image ], key: "featurette" } : undefined
      
    ].filter(content => content);
    
    for (const { images, key } of mappedContent) {
      const assets = artwork[key];
      const darkAssets = darkArtwork[key];
      
      if (!assets) {
        throw new Error(`Cannot find assets for key ${key}`);
      }
      
      images.forEach(target => {
        const mapped = new MappedImage(target, assets, darkAssets);
        Object.assign(target, mapped);
      });
    }
    
    // Create root view:
    const rootView = new RootView(contentRoot);
    
    const workContainer = document.querySelector(".root");
    workContainer.appendChild(rootView.node);
    
    // Configure body:
    document.body.classList.add("timeline-scroll");
    
    // Listen for color scheme changes:
    const handleColorSchemeChange = () => {
      const backgroundColor = getComputedStyle(document.documentElement)
      .getPropertyValue("--secondary-background-color");
      
      document.querySelector("meta[name=theme-color]").setAttribute("content", backgroundColor);
    };
    
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener("change", handleColorSchemeChange);
    handleColorSchemeChange();
  }
  
}

window.app = new P2023(document);
