// 
// TimelineViewCell.js
// portfolio
// 
// Created on 5/7/23
// 

import { View } from "cacao/ui";
import CaptionView from "./CaptionView";

class TimelineViewCell extends View {
  delegate;
  attributes;
  pictureNode;
  captionView;
  
  constructor(delegate){
    super();
    
    this.delegate = delegate;
    
    const { node } = this;
    
    const { style: nodeStyle } = node;
    nodeStyle.top = "0";
    nodeStyle.left = "0";
    nodeStyle.transformOrigin = "0 0";
    
    const pictureNode = document.createElement("button");
    pictureNode.className = "picture";
    pictureNode.addEventListener("click", this);
    
    node.appendChild(pictureNode);
    
    const captionView = new CaptionView();
    captionView.node.className = "caption";
    
    node.appendChild(captionView.node);
    
    this.pictureNode = pictureNode;
    this.captionView = captionView;
  }
  
  makeNode(document){
    return document.createElement("article");
  }
  
  configure(configuration){
    const { attributes, data, options } = configuration;
    
    const { node, pictureNode, captionView } = this;
    const { frame, frameForImage, frameForCaption, alphaForCaption, transform } = attributes;
    const px = "px";
    
    const { style: nodeStyle } = node;
    nodeStyle.width = frame.width + px;
    nodeStyle.height = frame.height + px;
    nodeStyle.transform = new DOMMatrix()
                              .translate(frame.x, frame.y)
                              .multiply(transform);
    
    const { style: pictureStyle } = pictureNode;
    pictureStyle.top = frameForImage.y + px;
    pictureStyle.left = frameForImage.x + px;
    pictureStyle.width = frameForImage.width + px;
    pictureStyle.height = frameForImage.height + px;
    
    const { style: captionStyle } = captionView.node;
    captionStyle.top = frameForCaption.y + px;
    captionStyle.left = frameForCaption.x + px;
    captionStyle.width = frameForCaption.width + px;
    captionStyle.height = frameForCaption.height + px;
    
    const isCaptionHidden = alphaForCaption == 0.0;
    captionStyle.display = isCaptionHidden ? "none" : ""; // "" is a reset.
    captionStyle.opacity = alphaForCaption;
    
    if (this.data != data) {
      this.data = data;
      
      // Setup caption:
      captionView.configure(data);
      
      // Setup image:
      const { image } = data;
      pictureNode.ariaLabel = image.alt;
      pictureNode.innerHTML = `
        <picture>
          <source srcset="${image.dark.webp}" type="image/webp" media="(prefers-color-scheme: dark)" />
          <source srcset="${image.webp}" type="image/webp" />
          
          <img src="${image.src}" alt="${image.alt}">
        </picture>
      `;
    }
    
    if (options) {
      const { selectable } = options;
      
      pictureNode.role = selectable ? "button" : "img";
      
      if (selectable) {
        pictureNode.removeAttribute("tabIndex");
      } else {
        pictureNode.tabIndex = "-1";
      }
    }
    
    this.attributes = attributes;
    this.selectable = (options.selectable) || false;
  }
  
  handleEvent(event){
    if (event.target !== this.pictureNode) {
      return;
    }
    
    switch (event.type){
      case "click":
        this.pictureNode.blur();
        
        if (this.selectable) {
          this.delegate.didSelectTimelineCellForItem(this, this.attributes.itemIndex);
        }
        break;
    }
    
  }
}

export default TimelineViewCell;
