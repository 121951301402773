// 
// App.js
// Cacao
// 
// Created on 8/23/22
// 

import { assert } from "../core";

class App {
  #document;
  #readyState;
  #documentHasFinishedLoading = false;
  
  constructor(document){
    assert(document, "A container `document` is required to use App.");
    
    this.#document = document;
    this.#attach();
  }
  
  documentReadyStateDidChange(document){
    // Override if needed.
  }
  
  documentDidLoad(document){
    // Override if needed.
  }
  
  #attach(){
    const document = this.#document;
    
    const handleDocumentState = () => {
      if (this.#readyState != document.readyState) {
        this.#readyState = document.readyState;
        this.documentReadyStateDidChange(document);
      }
      
      switch (document.readyState) {
        case "loaded":
        case "complete":
        case "interactive":
          // The document has finished loading. We can now access the DOM elements.
          if (!this.#documentHasFinishedLoading) {
            this.#documentHasFinishedLoading = true;
            
            this.documentDidLoad(document);
          }
          break;
      }
    };
    
    handleDocumentState();
    
    if (!this.#documentHasFinishedLoading) {
      document.addEventListener("readystatechange", handleDocumentState);
    }
  }
  
  get document(){
    return this.#document;
  }
  
}

export default App;
