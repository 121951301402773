// 
// TouchType.js
// Cacao
// 
// Created on 6/17/23
// 

class TouchType {
  
  static direct = 0;
  static indirect = 1;
  static pen = 2;
  
}

export default TouchType;
