// 
// AboutView.js
// portfolio
// 
// Created on 6/27/23
// 

import { View, PanGestureRecognizer, GestureRecognizerState } from "cacao/ui";
import ScrollEngine from "../scrolling/ScrollEngine";
import PolaroidCell from "./PolaroidCell";

class AboutView extends View {
  imageWrapperView;
  panGestureRecognizer;
  
  constructor(data){
    super();
    
    this.node.className = "about-images";
    this.node.style.position = "relative";
    this.data = data;
    
    const imageWrapper = new View();
    imageWrapper.node.className = "about-images-wrapper";
    imageWrapper.node.style.position = "relative";
    
    this.imageWrapperView = imageWrapper;
    
    this.addSubview(imageWrapper);
    
    for (const image of data) {
      this.insertPolaroid(image);
    }
  }
  
  handlePanGestureRecognizer(panGestureRecognizer, view){
    const { state, translation, event } = panGestureRecognizer;
    
    switch (state){
      case GestureRecognizerState.began:
        // Required so we cancel other recognizers:
        event.stopPropagation();
        
        // Stop and capture initial transform:
        view.animator?.stop();
        view.initialTransform = new DOMMatrix(view.node.style.transform);
        
        // Bring to front:
        view.superview.addSubview(view);
        
        // Begin lift:
        const origin = panGestureRecognizer.locationInView(view);
        const lift = () => view.beginLift({ origin });
        
        window.requestAnimationFrame(lift);
        break;
      case GestureRecognizerState.changed:
        view.node.style.transform = new DOMMatrix()
        .translate(translation.x, translation.y)
        .multiply(view.initialTransform);
        break;
      case GestureRecognizerState.ended:
      case GestureRecognizerState.cancelled:
        const { initialTransform } = view;
        
        const { velocity } = panGestureRecognizer;
        const parameters = { decelerationVelocity: velocity };
      
        const engine = new ScrollEngine();
        engine.directionalLockEnabled = false;
        engine.decelerationRate = 0.98; // ScrollEngineDecelerationRate.fast;
        engine.contentOffset = translation;
        engine.anchorLimits = [ engine.projectionForDragging(parameters) ];
        engine.endDragging(parameters, {
          willAnimate: () => {
            // Do nothing.
          },
          
          didAnimate: () => {
            const { contentOffset } = engine;
            const transform = new DOMMatrix()
            .translate(contentOffset.x, contentOffset.y)
            .multiply(initialTransform)
            
            view.node.style.transform = transform;
            view.initialTransform = transform;
          },
          
          didFinish: () => {
            // Do nothing.
          }
        });
        
        view.animator = engine;
        view.endLift();
        
        break;
    }
  }
  
  insertPolaroid(data){
    const view = new PolaroidCell();
    view.configure(data);
    
    const panGestureRecognizer = new PanGestureRecognizer();
    panGestureRecognizer.didChange = this.handlePanGestureRecognizer.bind(this, panGestureRecognizer, view);
    panGestureRecognizer.attach(view.node);
    
    view.gestureRecognizer = panGestureRecognizer;
    
    this.imageWrapperView.addSubview(view);
  }
  
  
}


export default AboutView;
