// 
// Logging.js
// Cacao
// 
// Created on 9/6/22
// 

export function pointee(instance){
  let pointee;
  try {
    pointee = (instance.constructor?.name || instance.toString());
  } catch {
    pointee = "[Unknown]"
  };
  return pointee;
}

export function tree(root, name, children){
  return (new TreeGrapher(name, children)).parse(root);
}

class TreeGrapher {
  
  constructor(name, children, limit = 1000){
    this.settings = {
      keyword: {
        name,
        children
      },
      maxLevel: limit,
    }
  }

  setPre(level, hasNext, parentPre = ''){
    return `${parentPre}${hasNext ? '├' : '└' }── `
  }

  setTransferPre(parentPre = '', hasNext){
    return `${parentPre}${hasNext ? '│' : ' '}   `
  }

  parse(tree, level = 0, parentPre = '', treeStr = ''){
    if (!this.check(tree, level)) return ''

    if (Array.isArray(tree)) {
      tree.forEach((child, index) => {
        const hasNext = tree[index + 1] ? true : false
        const children = child[this.settings.keyword.children]

        treeStr += `${this.setPre(level, hasNext, parentPre)}${child[this.settings.keyword.name]}\n`

        if (children) {
          treeStr += this.parse(children, level + 1, this.setTransferPre(parentPre, hasNext))
        }
      })
    } else {
      const children = tree[this.settings.keyword.children]
      treeStr = `${tree[this.settings.keyword.name]}\n`
      if (children) {
        treeStr += this.parse(children, level + 1)
      }
    }

    return treeStr
  }

  check(tree, level){
    if (typeof tree !== 'object') return false;
    if (level >= this.settings.maxLevel) return false;
    
    return true;
  }
}
