// 
// Assertions.js
// Cacao
// 
// Created on 9/6/22
// 

export function assert(condition, message){
    if (!condition) {
        throw new Error(message || "Assertion failed");
    }
}
