// 
// RootView.js
// portfolio
// 
// Created on 6/17/23
// 

import { View } from "cacao/ui";

import TimelineView from "./timeline/TimelineView";
import SidebarView from "./sidebar/SidebarView";
import PageContainerView from "./page/PageContainerView";
import AboutView from "./about/AboutView";
import slugify from "../scripts/slugify";

import Button from "./views/Button";
import Symbol from "./views/Symbol";

class RootView extends View {
  data;
  timelineView;
  sidebarView;
  backButton;
  
  constructor(data){
    super();
    
    this.data = data;
    this.node.className = "root-view";
    
    // Sidebar:
    const sidebarView = new SidebarView(data);
    sidebarView.node.className = "sidebar";
    sidebarView.delegate = this;
    
    this.sidebarView = sidebarView;
    
    this.addSubview(sidebarView);
    
    // Initialize timeline:
    const timelineView = new TimelineView(data.work);
    timelineView.delegate = this;
    
    this.timelineView = timelineView;
    
    this.addSubview(timelineView);
    
    // Navigation:
    const backButton = new Button();
    backButton.primaryAction = () => timelineView.popFocusedView({ animated: true });
    backButton.keyEquivalent = [ "Escape", "ArrowRight" ];
    backButton.node.className = "navigation-button back-button";
    backButton.node.title = "Back";
    
    backButton.addSubview(Symbol.chevronBackward);
    
    this.backButton = backButton;
    
    this.addSubview(backButton);
    
    // Window sizing:
    window.addEventListener("resize", () => this.layoutSubviews());
    window.addEventListener("focus", () => this.layoutSubviews());
    
    // Do a layout pass:
    this.layoutSubviews();
    
    // Route if needed:
    const currentPageLink = window.location.pathname;
    if (currentPageLink && currentPageLink != "/") {
      this.route(currentPageLink);
    }
  }
  
  layoutSubviews(){
    const { innerWidth: width, innerHeight: height } = window;
    const containerSize = { width, height };
    
    const { timelineView } = this;
    timelineView.containerSize = containerSize;
    timelineView.layoutSubviews();
    
    const { sidebarView } = this;
    sidebarView.node.style.left = `${timelineView.layout.options.contentInset.left}px`;
  }
  
  presentPage(link, { animated = false } = {}){
    const page = this.data.pages[link];
    
    if (!page) {
      throw new Error(`Cannot handle page "${link}".`);
    }
    
    this.setSubpage(link, page.title);
    
    const view = new View();
    view.node.className = `page-container ${link}`;
    view.node.innerHTML = page.body;
    
    if (link == "/about/"){
      view.addSubview(new AboutView(page.images));
    }
    
    const dismissButton = new Button();
    dismissButton.node.className = "navigation-button dismiss-button";
    dismissButton.node.title = "Close";
    dismissButton.primaryAction = () => {
      containerView.dismiss();
    };
    
    dismissButton.addSubview(Symbol.xmark);
    
    const containerView = new PageContainerView(view);
    containerView.didDisappear = () => {
      this.popSubpage();
    };
    containerView.navigationContainerView.addSubview(dismissButton);
    
    containerView.presentInView(this, { animated });
    
    // Workaround to avoid visible focused dismiss:
    const setDismissVisibility = (visiblity) => dismissButton.node.style.visibility = visiblity;
    setDismissVisibility("hidden");
    window.requestAnimationFrame(() => setDismissVisibility(""));
  }
  
  setSubpage(path = "/", subtitle){
    const { title } = this.data.header;
    
    document.title = (subtitle) ? `${subtitle} — ${title}` : title;
    window.history.replaceState({}, undefined, path);
  }
  
  popSubpage(){
    this.setSubpage();
  }
  
  route(path){
    const { data } = this;
    
    try {
      const currentPage = data.pages[path];
      if (currentPage) {
        this.presentPage(path);
      } else {
        const components = path.split("/").filter((letter) => letter !== "");
        
        if (components.length == 2) {
          const [ year, slug ] = components;
          const match = data.work.findIndex((item) => item.year == year && slugify(item.title) == slug);
          
          if (match != -1) {
            this.timelineView.focusView(match, { animated: false });
          } else {
            throw new Error("Slug didn't match.")
          }
        } else {
          throw new Error("Components didn't match for a slug.");
        }
      }
    } catch (error) {
      this.presentPage("/404/");
    }
  }
  
  // - SidebarView delegate
  
  sidebarDidSelectLink(_, link){
    this.presentPage(link, { animated: true });
  }
  
  // - TimelineView delegate
  
  timelineViewWillTransition(_, { isFocused, item } = state){
    this.node.classList.toggle("is-focused", isFocused);
    
    if (isFocused) {
      this.backButton.node.tabIndex = "1";
      
      const work = this.data.work[item];
      const slug = `/${work.year}/${slugify(work.title)}/`;
      
      this.setSubpage(slug, work.title);
    } else {
      this.backButton.node.removeAttribute("tabIndex");
      this.popSubpage();
    }
  }
  
  timelineViewDidTransition(_){
    // Nothing.
  }
  
  timelineViewFocusInteractionDidChange(_, { percentComplete }){
    this.sidebarView.configureDisappearPercent(percentComplete);
  }
  
  timelineViewDidScroll(_, { itemIndex } = {}){
    if (this.timelineView.isFocused) {
      const work = this.data.work[itemIndex];
      const slug = `/${work.year}/${slugify(work.title)}/`;
      
      this.setSubpage(slug, work.title);
    }
    
    this.sidebarView.configureCurrentYear(this.data.work[itemIndex].year);
  }
}

export default RootView;
