// 
// Symbol.js
// portfolio
// 
// Created on 6/24/23
// 

import { View } from "cacao/ui";

import chevronBackward from "bundle-text:/res/img/chevron-backward.svg";
import xmark from "bundle-text:/res/img/xmark.svg";

class Symbol extends View {
  
  static get chevronBackward(){
    return new Symbol(chevronBackward);
  }
  
  static get xmark(){
    return new Symbol(xmark);
  }
  
  constructor(src){
    super();
    
    this.node.innerHTML = src;
    this.node.className = "symbol";
  }
  
}

export default Symbol;
