// 
// Array.js
// Cacao
// 
// Created on 9/6/22
// 

import { assert } from "./Assertions";

class _Array extends Array {
  
  copy(){
    return this.slice(0);
  }
  
  append(element){
    assert(element, "`element` is required.");
    
    this.push(element);
  }
  
  appendContentsOf(array){
    assert(array, "`array` is required.");
    assert(Array.isArray(array), "`array` needs to be an Array.");
    
    this.push(... array);
  }
  
  insertAt(element, index){
    assert(element, "`element` is required.");
    assert(index >= 0 && index <= this.count, "`index` is out of bounds.");
    
    this.splice(index, 0, element); // insert `element` at index `index`.
  }
  
  removeFirst(element){
    assert(element, "`element` is required.");
    
    const index = this.indexOf(element);
    if (index != -1) {
      return this.splice(index, 1)[0];
    }
    return undefined;
  }
  
  removeLast(){
    const count = this.count;
    if (count > 0) {
      return this.removeAt(count - 1);
    }
    return undefined;
  }
  
  removeAt(index){
    assert(index >= 0 && index < this.count, "`index` is out of bounds.");
    
    if (index != -1) {
      return this.splice(index, 1)[0];
    }
    return undefined;
  }
  
  removeAll(whereFn){
    if (whereFn) {
      const elements = this.filter((element, index) => !(whereFn(element, index)));
      this.length = 0;
      this.push(... elements);
    } else {
      this.length = 0;
    }
  }
  
  swapAt(fromIndex, toIndex){
    const count = this.count;
    
    assert(fromIndex >= 0 && fromIndex < count, "`fromIndex` is out of bounds.");
    assert(toIndex >= 0 && toIndex < count, "`toIndex` is out of bounds.");
    
    if (fromIndex == toIndex) {
      return;
    }
    
    this.splice(toIndex, 0, this.splice(fromIndex, 1)[0]);
  }
  
  contains(element){
    assert(element, "`element` is required.")
    return this.indexOf(element) != -1;
  }
  
  get isEmpty(){
    return this.length <= 0;
  }
  
  get first(){
    const count = this.count;
    if (count > 0) {
      return this[0];
    }
    return undefined;
  }
  
  get last(){
    const count = this.count;
    if (count > 0) {
      return this[count - 1];
    }
    return undefined;
  }
  
  get count(){
    return this.length;
  }
  
}

export default _Array;
