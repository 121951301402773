// 
// Canvas2DView.js
// Cacao
// 
// Created on 9/8/22
// 

import { Array, assert } from "../core";
import { Rect } from "../graphics";
import View from "./View";

class Canvas2DView extends View {
  #options = { width: 1, height: 1, scale: 0 };
  #dirtyRect;
  #displayRequestID;
  
  makeNode(document){
    return document.createElement("canvas");
  }
  
  drawRect(rect){
    // override to render
  }
  
  setNeedsDisplay(){
    this.#dirtyRect = Rect.null;
    this.#scheduleDisplay();
  }
  
  setNeedsDisplayInRect(rect){
    assert(rect, "A rect must be passed.");
    
    rect = Rect.from(rect);
    
    this.#dirtyRect = this.#dirtyRect?.union(rect) || rect;
    this.#scheduleDisplay();
  }
  
  #scheduleDisplay(){
    const view = this;
    
    if (this.#displayRequestID) {
      return;
    }
    
    this.#displayRequestID = window.requestAnimationFrame(function BROWSER_IS_CALLING_OUT_TO_CANVAS2DVIEW_REQUEST_ANIMATION_FRAME_CALLBACK(){
      view.#displayRequestID = undefined;
      view.#display();
    });
  }
  
  #display(){
    const { node, options, context } = this;
    const { width, height, scale } = options;
    
    const bounds = Rect.make(0, 0, width, height);
    
    // Scale context:
    const scaled = (scale != 1);
    if (scaled) {
      context.save();
      context.scale(scale, scale);
    }
    
    // Calculate dirty rect. If rect is null, refresh the entire bounds of the canvas:
    let dirtyRect = this.#dirtyRect?.copy();
    
    if (!dirtyRect || dirtyRect.isNull) {
      dirtyRect = bounds;
    }
    
    // Intersect so we don't draw outside of bounds:
    dirtyRect = bounds.intersection(dirtyRect);
    
    // Clear entire bounds if `dirtyRect` is actually the whole view.
    // Maybe this should be a property.
    if (dirtyRect.equals(bounds)) {
      context.clearRect(0, 0, width, height);
    }
    
    // Call drawRect():
    this.drawRect(dirtyRect);
    
    // Restore context:
    if (scaled) {
      context.restore();
    }
    
    this.#dirtyRect = undefined;
  }
  
  set options(options){
    let { width, height, scale } = options;
    
    assert(width && height, "`width` and `height` are required options.");
    
    if (!scale || scale <= 0.01) {
      scale = window.devicePixelRatio;
    }
    
    this.#options = { width, height, scale };
    
    const { node } = this;
    node.width = width * scale;
    node.height = height * scale;
    node.style.width = `${width}px`;
    node.style.height = `${height}px`;
    
    this.setNeedsDisplay();
  }
  
  get options(){
    const { width, height, scale } = this.#options;
    return { width, height, scale };
  }
  
  get context(){
    return this.node.getContext("2d");
  }
  
}

export default Canvas2DView;
