// 
// RubberBanding.js
// Cacao
// 
// Created on 5/8/23
// 

function clampedValueUsingRubberBandingParameters(value, parameters){
  const { coeff = 0.55, dim } = parameters;
  
  return (1.0 - (1.0 / ((value * coeff / dim) + 1.0))) * dim;
}

export default { clamp: clampedValueUsingRubberBandingParameters };
