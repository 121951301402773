// 
// PolaroidCell.js
// portfolio
// 
// Created on 6/27/23
// 

import { View } from "cacao/ui";
import { Point } from "cacao/graphics";

class PolaroidCell extends View {
  wrapperView;
  imageView;
  
  constructor(){
    super();
    
    const { node } = this;
    const { style } = node;
    
    node.className = "polaroid";
    style.display = "block";
    style.position = "absolute";
    style.touchAction = "none";
    
    const wrapperView = new View();
    wrapperView.node.className = "polaroid-image-wrapper";
    
    this.wrapperView = wrapperView;
    
    this.addSubview(wrapperView);
    
    const imageView = new View();
    imageView.node.className = "polaroid-image";
    
    this.imageView = imageView;
    
    wrapperView.addSubview(imageView);
  }
  
  makeNode(document){
    return document.createElement("figure");
  }
  
  configure({ src, webp, alt } = {}){
    this.imageView.node.innerHTML = `
      <picture>
        <source srcset="${webp}" type="image/webp" />
        <img src="${src}" alt="${alt}">
      </picture>
    `;
    
    this.node.title = alt;
  }
  
  beginLift({ origin = Point.zero } = {}){
    this.node.classList.add("lift");
    this.node.transformOrigin = `${origin.x}px ${origin.y}px`;
  }
  
  endLift(){
    this.node.classList.remove("lift");
  }
  
}

export default PolaroidCell;
