// 
// View.js
// Cacao
// 
// Created on 8/23/22
// 

import { assert, pointee, Array } from "../core/index";

class View {
  #node;
  #subviews = new Array();
  #superview;
  #isNodeLoaded = false;
  
  constructor(){
    //
  }
  
  makeNode(document){
    return document.createElement("div");
  }
  
  addSubview(subview){
    this.insertSubview(subview, this.#subviews.length);
  }
  
  insertSubviewBelow(subview, siblingSubview){
    assert(subview !== siblingSubview, `Attempted to insert subview "${pointee(subview)}" as a sibling that is the same view.`);
    this.insertSubview(subview, this.#subviews.indexOf(siblingSubview));
  }
  
  insertSubviewAbove(subview, siblingSubview){
    assert(subview !== siblingSubview, `Attempted to insert subview "${pointee(subview)}" as a sibling that is the same view.`);
    this.insertSubview(subview, this.#subviews.indexOf(siblingSubview) + 1);
  }
  
  insertSubview(subview, index){    
    assert(subview, `Attempted to insert undefined subview at index ${index}`);
    assert(subview instanceof View, `Attempted to insert invalid subview "${pointee(subview)}" at index ${index}" that's not a View.`);
    assert(subview != this, `Attempted to insert view "${pointee(subview)}" as a subview of itself.`);
    assert(index => 0 && index < this.#subviews.length, `Attempted to insert subview "${pointee(subview)}" at invalid index ${index}"`);
    assert(index != -1, "Index cannot be -1.");
    
    if (subview.superview !== this) {
      subview.removeFromSuperview();
    }
    
    try {
      const { node } = this;
      const { childNodes } = node;
      
      if (index >= childNodes.length) {
        node.appendChild(subview.node);
      } else {
        node.insertBefore(subview.node, childNodes[index].node);
      }
    } catch (error){
      throw new Error(`Couldn't perform DOM hierarchy change when inserting view "${pointee(subview)}". Document may be in an inconsistent state.`, { cause: error });
    }
    
    this.#subviews.insertAt(subview, index);
    subview.#superview = this;
  }
  
  removeFromSuperview(){
    const { superview } = this;
    
    if (superview) {
      superview.#removeSubview(this);
      return;
    }
    
    if (this.isNodeLoaded) {
      const { node } = this;
      const { parentNode } = node;
      
      if (parentNode) {
        parentNode.removeChild(node);
      }
    }
  }
  
  isDescendantOfView(view){
    assert(view, `The view to test against the receiver cannot be undefined.`);
    assert(subview instanceof View, `The view to test against the receiver "${pointee(subview)}" is not a View.`);
    
    const visit = viewToTest => {
      if (viewToTest == view) {
        return true;
      }
      
      const parent = viewToTest.superview;
      return (parent) ? visit(parent) : false;
    }
    
    return visit(this);
  }
  
  #removeSubview(subview){
    assert(subview, "Subview is required.");
    
    this.#subviews.removeFirst(subview);
    
    if (subview.isNodeLoaded) {
      subview.node.remove();
    }
  }
  
  // Node
  
  makeNodeIfNeeded(){
    if (this.#isNodeLoaded){
      return;
    }
    
    // TODO: Support passing custom `document` instance, shadow DOM?
    let node;
    try {
      node = this.makeNode(document);
    } catch {
      throw new Error("A DOM node couldn't be materialized for this View.");
    }
    
    assert(node, "The result of -makeNode() must return a DOM node for this View.");
    assert(node instanceof Node, "A DOM node must be an instance of Node.");
    
    this.#node = node;
    this.#isNodeLoaded = true;
  }
  
  // Getters
  
  get isNodeLoaded(){
    return this.#isNodeLoaded;
  }
  
  get node(){
    this.makeNodeIfNeeded();
    assert(this.#node, "The node shouldn't be undefined at this point.");
    
    return this.#node;
  }
  
  get subviews(){
    return this.#subviews.copy();
  }
  
  get superview(){
    return this.#superview;
  }
  
}

export default View;
