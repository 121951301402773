// 
// PanGestureRecognizer.js
// Cacao
// 
// Created on 5/8/23
// 

import GestureRecognizer from "./GestureRecognizer.js"
import State from "./GestureRecognizerState.js"

class PanGestureRecognizer extends GestureRecognizer {
  
  constructor(){
    super()
    this._touchesSamplingQueue = [];
  }
  
  set translation(value){
    this._initialTouchPosition = value;
    this._latestTouchPosition = value;
  }
  
  get translation(){
    let x = 0, y = 0
    
    const initial = this._initialTouchPosition, latest = this._latestTouchPosition;
    
    if (initial && latest) {
      x = latest.x - initial.x;
      y = latest.y - initial.y;
    }
    
    return { x, y };
  }
  
  get velocity(){
    this.dequeueSampledTouchesBeforeInterval(1000);
    
    const queue = this._touchesSamplingQueue;
    
    if (queue.length < 2) {
      return { x: 0, y: 0 }
    }
    
    const first = queue[0], last = queue[queue.length - 1];
    const distance = { x: last.position.x - first.position.x, y: last.position.y - first.position.y };
    const time = (last.time - first.time) / 1000;
    return { x: distance.x / time, y: distance.y / time };
  }
  
  // - Sampling touches
  
  addSampleForTouchPosition(position){
    this._touchesSamplingQueue.push({ position, time: Date.now() });
    this.dequeueSampledTouchesBeforeInterval(50);
  }
  
  dequeueSampledTouchesBeforeInterval(duration){
    const queue = this._touchesSamplingQueue;
    const expiration = (Date.now() - duration);
    
    while (queue.length && queue[0].time < expiration) {
      queue.shift();
    }
  }
  
  updateLatestTouchPosition(position, event){
    position = { x: position.x, y: position.y };
    
    this._latestTouchPosition = position;
    this.addSampleForTouchPosition(position);
  }
  
  // - State overrides
  
  reset(){
    super.reset();
    
    this._latestTouchPosition = undefined;
    this._initialTouchPosition = undefined;
    this._touchesSamplingQueue.splice(0);
  }
  
  began(touches, event){
    if (this.state == State.possible) {
      this._initialTouchPosition = { x: touches[0].x, y: touches[0].y };
      this.state = State.began;
    }
  }
  
  moved(touches, event){
    this.updateLatestTouchPosition(touches[0], event);
    this.state = State.changed;
  }
  
  ended(touches, event){
    if (this.state == State.began || this.state == State.changed) {
      this.updateLatestTouchPosition(touches[0], event);
      this.state = State.ended;
    }
  }
  
  cancelled(touches, event){
    if (this.state == State.began || this.state == State.changed) {
      this.state = State.cancelled;
    }
  }
  
}

export default PanGestureRecognizer
