// 
// SnapRulerView.js
// portfolio
// 
// Created on 6/19/23
// 

import { View } from "cacao/ui";

class SnapRulerView extends View {
  _stops = [];
  _nodes = [];
  _enabled = false;
  
  constructor(){
    super();
    
    this.node.className = "snap-ruler";
    this.configure();
  }
  
  configure(){
    const { stops, enabled, node } = this;
    
    if (this._invalidateStops) {
      if (stops.length > 0) {
        this._nodes = [];
        
        const fragment = document.createDocumentFragment();
        
        stops.forEach((stop, index) => {
          const element = document.createElement("div");
          element.className = "snap-ruler-stop";
          
          const { style: elementStyle } = element;
          elementStyle.position = "absolute";
          elementStyle.width = "1px";
          elementStyle.height = "1px";
          elementStyle.display = "block";
          elementStyle.top = `${stop}px`;
          elementStyle.left = "0px";
          elementStyle.scrollSnapAlign = "start";
          elementStyle.scrollSnapStop = "always";
          
          this._nodes[index] = element;
          
          fragment.appendChild(element);
        });
        
        node.innerHTML = "";
        node.appendChild(fragment);
      } else {
        node.innerHTML = "";
      }
    }
    
    const { style: nodeStyle } = node;
    nodeStyle.position = "absolute";
    nodeStyle.display = enabled ? "block" : "none";
    nodeStyle.pointerEvents = "none";
  }
  
  set stops(stops){
    if (stops !== this._stops) {
      const canUpdate = (stops.length == this._stops.length);
      this._stops = stops;
      
      if (canUpdate) {
        stops.forEach((stop, index) => {
          const element = this._nodes[index];
          element.style.top = `${stop}px`;
        });
      } else {
        this._invalidateStops = true;
        this.configure();
        this._invalidateStops = false;
      }
    }
  }
  
  get stops(){
    return this._stops.slice(0);
  }
  
  set enabled(enabled){
    if (this._enabled != enabled) {
      this._enabled = enabled;
      this.configure();
    }
  }
  
  get enabled(){
    return this._enabled;
  }
  
}

export default SnapRulerView;
