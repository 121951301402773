// 
// SpringTimingParameters.js
// Cacao
// 
// Created on 5/8/23
// 

const { sqrt, exp, cos, sin, cosh, sinh } = Math;

class SpringTimingParameters {
  
  constructor(options = {}){
    const {
      damping = 10,
      mass = 1,
      stiffness = 100,
      initialVelocity = { dx: 0, dy: 0 }
    } = options;
    
    this.damping = damping;
    this.mass = mass;
    this.stiffness = stiffness;
    this.initialVelocity = initialVelocity;
  }
  
  copy(){
    const {
      damping,
      mass,
      stiffness,
      initialVelocity
    } = this;
    
    return new SpringTimingParameters({ damping, mass, stiffness, initialVelocity });
  }
  
  copyTimingFunction(options = {}){
    const { damping: b, mass: m, stiffness: k } = this;
    const v0 = this.#initialVelocityForVelocityAxis(options.velocityAxis);
    
    return (t) => {
      const beta = b / (2.0 * m);
      const omega0 = sqrt(k / m);
      const omega1 = sqrt((omega0 * omega0) - (beta * beta));
      const omega2 = sqrt((beta * beta) - (omega0 * omega0));
      const x0 = -1.0;
      const envelope = exp(-beta * t);
      
      let fraction;
      
      if (beta < omega0) {
        fraction = -x0 + envelope * (x0 * cos(omega1 * t) + ((beta * x0 + v0) / omega1) * sin(omega1 * t));
      } else if (beta == omega0) {
        fraction = -x0 + envelope * (x0 + (beta * x0 + v0) * t);
      } else {
        fraction = -x0 + envelope * (x0 * cosh(omega2 * t) + ((beta * x0 + v0) / omega2) * sinh(omega2 * t));
      }
      
      return fraction;
    };
  }
  
  #initialVelocityForVelocityAxis(axis){
    switch (axis) {
      case SpringTimingParameters.VelocityAxis.x:
        return this.initialVelocity.dx;
      case SpringTimingParameters.VelocityAxis.y:
        return this.initialVelocity.dy;
      default:
        return 0;
    }
  }
  
  static get VelocityAxis(){
    return {
      x: 1,
      y: 2
    }
  }
}

export default SpringTimingParameters;
