// 
// Touch.js
// Cacao
// 
// Created on 5/8/23
// 

class Touch {
  
  constructor(){
    this.x = 0;
    this.y = 0;
    this.tapCount = 0;
  }
  
}

export default Touch;
